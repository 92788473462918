<template>
  <div>
    <ejs-dialog
      ref="blankTimeSettingPopup"
      :header="'숨김타임 설정'"
      :allowDragging="true"
      :showCloseIcon="true"
      :width="popupDialogWidth"

      height="800"
      :animationSettings="{ effect: 'None' }"
      :isModal="true"
      :close="onBlankTimeSettingPopupClosed"
    >
      <div class="window blinkTimeSetting">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div
                    class="section-body"
                  >
<!--                    :class="reservationCourseSectionBodyClassName"-->
                    <ejs-grid-wrapper
                      v-for="(
                        timeTableCourse, timeTableCourseIdx
                      ) in timeTableCourseLists"
                      :key="timeTableCourse.courseCode"
                      :style="propsData"
                      @click.native="courseDivClicked(timeTableCourseIdx)"
                      ref="timeTableGrid"
                      :provides="gridProvide"
                      :columns="courseDivColumns"
                      :data-source="
                        timeTableCourseLists[timeTableCourseIdx].timeTables
                      "
                      :class="courseActiveIndex===timeTableCourseIdx ? 'border-box' : ''"
                      :allowResizing="true"
                      :editSettings="timeTableGridEditSettings"
                      :allowSorting="true"
                      :allowFiltering="true"
                      @queryCellInfo="timeTableQueryCellInfo"
                      @headerCellInfo="timeTableHeaderCellInfo($event,timeTableCourseIdx)"
                      @dataBound="dataBoundEvent"
                    >
                    </ejs-grid-wrapper>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="apply keyColor">
              <erp-button
                  buttonDiv="GET"
                  :isShortcutButton="true"
                  @click.native="getTimeTableByTimeStatus"
                  :isIconCustom="true"
              >
                새로고침
              </erp-button>
            </li>
            <li class="apply keyColor">
              <erp-button
                  buttonDiv="SAVE"
                  :is-shortcut-button="true"
                  :isIconCustom="true"
                  @click.native="applyButtonClicked"
              >
                적용
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="closeBlankTimeSettingPopup"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import { sortBy as _sortBy } from "lodash";
import ErpButton from "@/components/button/ErpButton.vue";
import numberTemplate from "@/views/common/template/GridNumberTemplate";

import {
  Edit,
  Filter,
  Sort,
  VirtualScroll,
  Resize,
  ForeignKey,
} from '@syncfusion/ej2-vue-grids';

import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import commonMixin from "@/views/layout/mixin/commonMixin";

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { reservationCourseGridSectionBodyClassName } from "@/utils/gridUtil";

import { getTimeTableByTimeStatus } from "@/api/timeTable";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {getFormattedDate} from "@/utils/date";

export default {
  name: "BlankTimeSettingPopup",
  mixins: [commonMixin, confirmDialogMixin],
  props:['propsData','filterCourse'],
  components: { EjsGridWrapper, ErpButton },
  data: function () {
    return {
      popupDialogWidth: null,
      timeStatus: null,
      timeOption: {},
      timeTableCourseLists: [],
      courseActiveIndex: -1,
      timeTableViewOption: {
        partDivOption: commonCodesGetCommonCode("PART_DIV"),
      },
      numberTemplate: function () {
        return {
          template: numberTemplate,
        };
      },
      timeTableGridEditSettings: {
        allowEditing: true,
        allowAdding: false,
        allowDeleting: false,
        mode: "Batch",
        showConfirmDialog: false,
      },
      timeTableGridFilterSettings: { type: "Menu" },
      courseDivColumns: [
        {
          field: "timeId",
          isPrimaryKey: true,
          allowEditing: false,
          type: "integer",
          visible: false,
        },
        {
          allowEditing: true,
          field: "resveCourse",
          headerText: "코스",
          textAlign: "center",
          columns:[
            {
              field: "resveDateMMDD",
              allowEditing: false,
              type: "string",
              headerText: "일자",
              minWidth: "16",
              width: "70",
              textAlign: "center",
              isDateType: true,
            },
            {
              field: "dwName",
              allowEditing: false,
              type: "string",
              headerText: "요일",
              minWidth: "16",
              width: "60",
              textAlign: "center",
            },
            {
              field: "partDivName",
              allowEditing: false,
              type: "string",
              headerText: "부",
              minWidth: "16",
              width: "60",
              textAlign: "center",
            },
            {
              field: "resveTime",
              allowEditing: false,
              type: "string",
              headerText: "시간",
              minWidth: "16",
              width: "70",
              textAlign: "center",
            },
            {
              field: "timeStatusFlag",
              headerText: "",
              editType: "booleanedit",
              type: "boolean",
              displayAsCheckBox: true,
              isSelectAllColumn: true,
              textAlign: "Center",
              minWidth: "16",
              width: "40",
            },
          ],
        }
      ],
      gridProvide: [Edit, Filter, Sort, VirtualScroll, Resize, ForeignKey],
    };
  },
  computed: {
    reservationCourseSectionBodyClassName() {
      return reservationCourseGridSectionBodyClassName(
        this.timeTableCourseLists,
        this.$t("className.reservation.reservationCourseNumPrefix")
      );
    },
  },
  created: function () {
    const courseCodes = commonCodesGetCommonCode("COURSE_CODE", true);

    this.popupDialogWidth = Math.round(393.3 * courseCodes.length);
  },

  methods: {
    timeTableQueryCellInfo(args) {
      if (args.column.field === "timeStatusFlag") {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    timeTableHeaderCellInfo(args, timeTableCourseIdx) {
      if (args.cell.column.headerText === "NO") {
        args.node.classList.add(this.$t("className.grid.noSortFilter"));
      }
      if (args.cell.column.field === "resveCourse") {
        args.node.innerText =
            this.timeTableCourseLists[timeTableCourseIdx].courseName;
        args.node.style.textAlign = "center";
      }
    },
    onBlankTimeSettingPopupClosed: function () {
      this.$emit("popupClosed");
    },
    showBlankTimeSettingPopup(timeStatus, timeOption) {
      this.timeStatus = timeStatus;
      this.timeOption = timeOption;
      this.$refs.blankTimeSettingPopup.show();
      this.getTimeTableByTimeStatus();
    },
    closeBlankTimeSettingPopup: function () {
      this.$refs.blankTimeSettingPopup.hide();
    },
    // saveBlankTimeSettingPopup: function () {
    //   let data = {
    //     remarks: null,
    //     timeTableRequestList: []
    //   };
    //
    //   let row = 0;
    //   const gridLength = this.$refs.timeTableGrid.length;
    //
    //   for (let i = 0; i < gridLength; i++) {
    //     this.$refs.timeTableGrid[i].saveCell();
    //
    //     const changedRecords = this.$refs.timeTableGrid[i].getBatchChanges().changedRecords;
    //
    //     changedRecords.forEach(record => {
    //       data.timeTableRequestList[row] = record;
    //       delete data.timeTableRequestList[row].selfOpenFlag;
    //       delete data.timeTableRequestList[row].joinOpenFlag;
    //       if (record.timeStatusFlag) {
    //         data.timeTableRequestList[row].timeStatusFlag = true;
    //         data.timeTableRequestList[row].timeStatus = "BLANK";
    //       } else {
    //         data.timeTableRequestList[row].timeStatusFlag = false;
    //         data.timeTableRequestList[row].timeStatus = 'EMPTY';
    //       }
    //
    //       row++;
    //     });
    //   }
    //
    //   this.saveTimeStatusSetting(data);
    // },
    courseDivClicked: function (courseIdx) {
      this.courseActiveIndex = courseIdx;
    },
    getTimeTableByTimeStatus() {
      const { resveDateFrom, resveDateTo, bsnCode, partDiv, resveCourse, resveTimeFrom, resveTimeTo } = this.timeOption;

      getTimeTableByTimeStatus(
        bsnCode,
        partDiv,
        resveCourse,
        resveDateFrom,
        resveDateTo,
        resveTimeFrom,
        resveTimeTo,
        this.timeStatus
      )
        .then((response) => {
          this.timeTableCourseLists = response.value.timeTableCourseLists?.filter((list)=>this.filterCourse.includes(list.courseCode))
            .map(item => {
              if (item.timeTables) {
                item.timeTables = _sortBy(item.timeTables, ["partDiv", "resveTime"]).map(item => ({
                  ...item,
                  resveDateMMDD: getFormattedDate(item.resveDate, "MM/DD")
                }));
              }
              return item;
            });
        })
        .catch((error) => {
          console.log("getTimeTableByTimeStatus.err.===>", error);
        });
    },
    // saveTimeStatusSetting (data) {
    //   console.log('data.===>', data);
    //   if (data.timeTableRequestList.length === 0) {
    //     this.errorToast(this.$t('main.popupMessage.noDataToSave'));
    //     return;
    //   }
    //
    //   saveTimeStatusSetting('BLANK', data).then(response => {
    //     console.log('saveTimeStatusSetting.===>', response);
    //     this.$emit('popupClosed', { isReload: true });
    //     this.$refs.blankTimeSettingPopup.hide();
    //   }).catch(error => {
    //     console.log('saveTimeStatusSetting.err.===>', error);
    //   });
    // },
    dataBoundEvent() {},
    async applyButtonClicked() {
      let reservationTimes = [];

      this.$refs.timeTableGrid.forEach((grid) => {
        grid.saveCell();

        const { changedRecords: changedRecords } = grid.getBatchChanges();

        changedRecords.forEach((record) => {
          const reservationTime = {
            timeId: record.timeId,
            optFlag: record.optFlag,
            resveDate: record.resveDate,
            settingTimeStatus: "BLANK",
            settingTimeStatusRemarks: null,
            timeSelectCheck: record.timeStatusFlag,
          };

          reservationTimes.push(reservationTime);
        });
      });

      if (!(reservationTimes.length > 0)) {
        this.errorToast(this.$t("main.popupMessage.noDataToSave"));
        return;
      }

      await GolfErpAPI.reservationTimesStatusSetting(reservationTimes);

      this.$emit("popupClosed", { isReload: true });
      this.$refs.blankTimeSettingPopup.hide();
    },
  },
};
</script>
<style scoped>
.border-box {
  border: 2px solid #1f5b41 !important;
  box-sizing: border-box;
  z-index: 10;
}
</style>
<template>
  <div>
    <ejs-dialog
      ref="reservationTimeCreatePopup"
      :header="'예약타임 생성'"
      :allowDragging="true"
      :showCloseIcon="true"
      width="851"
      :animationSettings="{ effect: 'None' }"
      :isModal="true"
      :close="onReservationTimeCreatePopupClosed"
    >
      <div class="window reservationTimeGeneration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">예약일자</div>
                    <ul class="content">
                      <li class="item dateRange">
                        <input-date-range
                          v-model="reservationDateRange"
                          type="lookup-condition"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field businessCategory">
                    <div class="title">영업구분</div>
                    <ul class="content">
                      <li class="item">
                        <ejs-dropdownlist
                          ref="bsnCodeDropdown"
                          v-model="timeCreatePopupOption.bsnCode"
                          :dataSource="timeCreatePopupSetting.bsnCodeOptions"
                          :allowFiltering="false"
                          :fields="{ text: 'comName', value: 'comCode' }"
                          cssClass="lookup-condition-dropdown"
                        ></ejs-dropdownlist>
                      </li>
                    </ul>
                  </li>

                  <li class="field dayOfWeekDivision">
                    <!-- 필수 : required -->
                    <div class="title">요일구분</div>
                    <div class="content">
                      <ul class="row">
                        <li class="item form-group check weekstyle">
                          <!-- 포커스 : focus -->
                          <ul class="check">
                            <li>
                              <label>
                                <input
                                    type="checkbox"
                                    id="dwValueAll"
                                    v-model="dwValueAllValueModel"
                                />
                                <i></i>
                                <div class="label">전체</div>
                              </label>
                            </li>
                            <li
                                :key="code.comCode"
                                v-for="code in timeCreatePopupOption.dwCodeOptions"
                                :class="code.codeAbrv.toLowerCase()"
                            >
                              <label>
                                <input
                                    :ref="`dwCode_${code.comCode}`"
                                    type="checkbox"
                                    :key="`checkbox_${code.comCode}`"
                                    :id="code.comCode"
                                    v-model="dwInfo.dwValue"
                                    :value="code.comCode"
                                />
                                <i></i>
                                <div
                                    class="label"
                                    :key="`label_checkbox_${code.comCode}`"
                                    :for="code.comCode"
                                >
                                  {{ code.comName }}
                                </div>
                              </label>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </li>

                </ul>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-data header">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field check">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="checkbox"
                                            v-model="allCheckFlag"
                                            @click="onCheckFlagClick"
                                          />
                                          <i></i>
                                        </label>
                                      </li>
                                    </ul>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field part">
                              <!-- 필수 : required -->
                              <div class="title">부구분</div>
                            </li>
                            <li class="field startTime">
                              <!-- 필수 : required -->
                              <div class="title">시작시간</div>
                            </li>
                            <li class="field teeOffInterval">
                              <!-- 필수 : required -->
                              <div class="title">티오프 간격</div>
                            </li>
                            <li class="field numberOfTeams">
                              <!-- 필수 : required -->
                              <div class="title">팀수</div>
                            </li>
                            <li class="field visitCnt">
                              <!-- 필수 : required -->
                              <div class="title">내장인원수</div>
                            </li>
                            <li class="field endTime">
                              <!-- 필수 : required -->
                              <div class="title">종료시간</div>
                            </li>
                            <li class="field hole">
                              <!-- 필수 : required -->
                              <div class="title">홀구분</div>
                            </li>
                            <li class="field course">
                              <!-- 필수 : required -->
                              <div class="title">코스</div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                    <div class="body-data body">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content" v-if="isVisiblePartDiv(1)">
                            <li class="field check">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="checkbox"
                                            v-model="
                                              timeCreateList[0].checkFlag
                                            "
                                          />
                                          <i></i>
                                        </label>
                                      </li>
                                    </ul>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field part">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    1부
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field startTime">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-time
                                        :format="'HH:mm'"
                                        v-model="timeCreateList[0].startTime"
                                        autocomplete="off"
                                        @change="onStartTimeChange($event, 0)"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field teeOffInterval">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        v-model="
                                          timeCreateList[0]
                                            .teeOffIntervalList[0]
                                        "
                                        autocomplete="off"
                                        @change="teamCntChange($event, 0)"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        v-model="
                                          timeCreateList[0]
                                            .teeOffIntervalList[1]
                                        "
                                        autocomplete="off"
                                        @change="teamCntChange($event, 0)"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        v-model="
                                          timeCreateList[0]
                                            .teeOffIntervalList[2]
                                        "
                                        autocomplete="off"
                                        @change="teamCntChange($event, 0)"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field numberOfTeams">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        ref="teamCnt1"
                                        v-model="timeCreateList[0].teamCnt"
                                        autocomplete="off"
                                        @change="teamCntChange($event, 0)"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field visitCnt">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        ref="visitCnt1"
                                        v-model="timeCreateList[0].visitCnt"
                                        :min="1"
                                        :max="5"
                                        :propMaxLength="1"
                                        autocomplete="off"
                                        @change="teamCntChange($event, 0)"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field endTime">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-time
                                        :format="'HH:mm'"
                                        :min="timeCreateList[0].startTime"
                                        v-model="timeCreateList[0].endTime"
                                        :disabled="true"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field hole">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="holeDivDropdown"
                                        v-model="timeCreateList[0].holeDiv"
                                        :dataSource="
                                          timeCreatePopupSetting.holeDivOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="{
                                          text: 'comName',
                                          value: 'comCode',
                                        }"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field course">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="courseDropdown"
                                        v-model="timeCreateList[0].courseOption"
                                        :dataSource="
                                          timeCreatePopupSetting.courseOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="{
                                          text: 'comName',
                                          value: 'comCode',
                                        }"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <ul class="data-content" v-if="isVisiblePartDiv(2)">
                            <li class="field check">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="checkbox"
                                            v-model="
                                              timeCreateList[1].checkFlag
                                            "
                                          />
                                          <i></i>
                                        </label>
                                      </li>
                                    </ul>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field part">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    2부
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field startTime">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-time
                                        :format="'HH:mm'"
                                        v-model="timeCreateList[1].startTime"
                                        autocomplete="off"
                                        @change="onStartTimeChange($event, 1)"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field teeOffInterval">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        v-model="
                                          timeCreateList[1]
                                            .teeOffIntervalList[0]
                                        "
                                        autocomplete="off"
                                        @change="teamCntChange($event, 1)"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        v-model="
                                          timeCreateList[1]
                                            .teeOffIntervalList[1]
                                        "
                                        autocomplete="off"
                                        @change="teamCntChange($event, 1)"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        v-model="
                                          timeCreateList[1]
                                            .teeOffIntervalList[2]
                                        "
                                        autocomplete="off"
                                        @change="teamCntChange($event, 1)"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field numberOfTeams">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        ref="teamCnt2"
                                        v-model="timeCreateList[1].teamCnt"
                                        autocomplete="off"
                                        @change="teamCntChange($event, 1)"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field visitCnt">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        ref="visitCnt2"
                                        v-model="timeCreateList[1].visitCnt"
                                        :min="1"
                                        :max="5"
                                        :propMaxLength="1"
                                        autocomplete="off"
                                        @change="teamCntChange($event, 1)"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field endTime">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-time
                                        :format="'HH:mm'"
                                        :min="timeCreateList[1].startTime"
                                        v-model="timeCreateList[1].endTime"
                                        :disabled="true"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field hole">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="holeDivDropdown"
                                        v-model="timeCreateList[1].holeDiv"
                                        :dataSource="
                                          timeCreatePopupSetting.holeDivOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="{
                                          text: 'comName',
                                          value: 'comCode',
                                        }"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field course">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="courseDropdown"
                                        v-model="timeCreateList[1].courseOption"
                                        :dataSource="
                                          timeCreatePopupSetting.courseOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="{
                                          text: 'comName',
                                          value: 'comCode',
                                        }"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <ul class="data-content" v-if="isVisiblePartDiv(3)">
                            <li class="field check">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="checkbox"
                                            v-model="
                                              timeCreateList[2].checkFlag
                                            "
                                          />
                                          <i></i>
                                        </label>
                                      </li>
                                    </ul>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field part">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    3부
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field startTime">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-time
                                        :format="'HH:mm'"
                                        v-model="timeCreateList[2].startTime"
                                        autocomplete="off"
                                        @change="onStartTimeChange($event, 2)"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field teeOffInterval">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        v-model="
                                          timeCreateList[2]
                                            .teeOffIntervalList[0]
                                        "
                                        autocomplete="off"
                                        @change="teamCntChange($event, 2)"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        v-model="
                                          timeCreateList[2]
                                            .teeOffIntervalList[1]
                                        "
                                        autocomplete="off"
                                        @change="teamCntChange($event, 2)"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        v-model="
                                          timeCreateList[2]
                                            .teeOffIntervalList[2]
                                        "
                                        autocomplete="off"
                                        @change="teamCntChange($event, 2)"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field numberOfTeams">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        ref="teamCnt3"
                                        v-model="timeCreateList[2].teamCnt"
                                        autocomplete="off"
                                        @change="teamCntChange($event, 2)"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field visitCnt">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        ref="visitCnt3"
                                        v-model="timeCreateList[2].visitCnt"
                                        :min="1"
                                        :max="5"
                                        :propMaxLength="1"
                                        autocomplete="off"
                                        @change="teamCntChange($event, 2)"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field endTime">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-time
                                        :format="'HH:mm'"
                                        :min="timeCreateList[2].startTime"
                                        v-model="timeCreateList[2].endTime"
                                        :disabled="true"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field hole">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="holeDivDropdown"
                                        v-model="timeCreateList[2].holeDiv"
                                        :dataSource="
                                          timeCreatePopupSetting.holeDivOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="{
                                          text: 'comName',
                                          value: 'comCode',
                                        }"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field course">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="courseDropdown"
                                        v-model="timeCreateList[2].courseOption"
                                        :dataSource="
                                          timeCreatePopupSetting.courseOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="{
                                          text: 'comName',
                                          value: 'comCode',
                                        }"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="create keyColor">
              <erp-button
                  buttonDiv="SAVE"
                  :is-shortcut-button="true"
                  :isIconCustom="true"
                  :disabled="!saveButtonEnabledFlag"
                  @click.native="saveReservationTimeCreatePopup"
              >
                생성
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="closeReservationTimeCreatePopup"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style>
.weekstyle{
  list-style-type: none !important;
}
</style>


<script>
import _ from "lodash";
import {
  commonCodesGetCommonCode,
  commonCodesGetStandardInfo,
} from "@/utils/commonCodes";
import { getFormattedDate } from "@/utils/date";
import { validateFormRefs, validateFormRefsClear } from "@/utils/formUtil";
import commonMixin from "@/views/layout/mixin/commonMixin";
import ErpButton from "@/components/button/ErpButton.vue";

import { addTimeTable , getTimeSetting } from "@/api/timeTable";
import InputTime from "@/components/common/datetime/InputTime";

import InputDateRange from "@/components/common/datetime/InputDateRange";
import InputNumber from "@/components/common/InputNumber";

import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

export default {
  name: "reservationTimeCreatePopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: { InputNumber, InputDateRange, InputTime, ErpButton },
  props:["filterCourse","courseDiv"],
  data() {
    return {
      allCheckFlag: false,
      timeCreatePopupSetting: {
        bsnCodeOptions: [],
        holeDivOptions: [],
        courseOptions: [],
      },
      timeCreatePopupOption: {
        resveDateFrom: null,
        resveDateTo: null,
        bsnCode: null,
        timeCreateList: [],
        dwCodeOptions:[],
      },
      timeCreateList: [
        {
          checkFlag: false,
          partDiv: "1",
          startTime: "07:00",
          teeOffIntervalList: [
            commonCodesGetStandardInfo("teeOffIntrvl1") || 0,
            commonCodesGetStandardInfo("teeOffIntrvl2") || 0,
            commonCodesGetStandardInfo("teeOffIntrvl3") || 0,
          ],
          teamCnt: 0,
          visitCnt: 4,
          endTime: null,
          holeDiv: "18",
          course: null,
          courseOption: "ALL",
        },
        {
          checkFlag: false,
          partDiv: "2",
          startTime: "10:30",
          teeOffIntervalList: [
            commonCodesGetStandardInfo("teeOffIntrvl1") || 0,
            commonCodesGetStandardInfo("teeOffIntrvl2") || 0,
            commonCodesGetStandardInfo("teeOffIntrvl3") || 0,
          ],
          teamCnt: 0,
          visitCnt: 4,
          endTime: null,
          holeDiv: "18",
          course: null,
          courseOption: "ALL",
        },
        {
          checkFlag: false,
          partDiv: "3",
          startTime: "15:30",
          teeOffIntervalList: [
            commonCodesGetStandardInfo("teeOffIntrvl1") || 0,
            commonCodesGetStandardInfo("teeOffIntrvl2") || 0,
            commonCodesGetStandardInfo("teeOffIntrvl3") || 0,
          ],
          teamCnt: 0,
          visitCnt: 4,
          endTime: null,
          holeDiv: "18",
          course: null,
          courseOption: "ALL",
        },
      ],
      saveButtonEnabledFlag: true,
      dwInfo: {
        dwValueCheckAll: true,
        dwValue: [1,2,3,4,5,6,7],
      },
    };
  },
  computed: {
    dwValueAllValueModel: {
      get() {
        return (
            this.dwInfo.dwValue.length ===
            this.timeCreatePopupOption.dwCodeOptions.map(({ comCode }) => comCode)
                .length
        );
      },
      set(value) {
        this.dwInfo.dwValue = value
            ? this.timeCreatePopupOption.dwCodeOptions.map(
                ({ comCode }) => comCode
            )
            : [];
      },
    },
    reservationDateRange: {
      get: function () {
        return {
          from: this.timeCreatePopupOption.resveDateFrom,
          to: this.timeCreatePopupOption.resveDateTo,
        };
      },
      set: function (reservationDateRange) {
        this.timeCreatePopupOption.resveDateFrom = reservationDateRange.from;
        this.timeCreatePopupOption.resveDateTo = reservationDateRange.to;
      },
    },

    reservationTimeCreationValidator() {
      return {
        teamCnt1: {
          required: true,
          custom: {
            method: () =>
              this.timeCreateList[0].checkFlag
                ? 0 < this.timeCreateList[0].teamCnt
                : true,
            message: "팀 수를 입력해주세요.",
          },
        },
        teamCnt2: {
          required: true,
          custom: {
            method: () =>
              this.timeCreateList[1].checkFlag
                ? 0 < this.timeCreateList[1].teamCnt
                : true,
            message: "팀 수를 입력해주세요.",
          },
        },
        teamCnt3: {
          required: true,
          custom: {
            method: () =>
              this.timeCreateList[2].checkFlag
                ? 0 < this.timeCreateList[2].teamCnt
                : true,
            message: "팀 수를 입력해주세요.",
          },
        },
        visitCnt1: {
          required: true,
          custom: {
            method: () =>
              this.timeCreateList[0].visitCnt,
            message: "내장인원수를 입력해주세요.",
          },
        },
        visitCnt2: {
          required: true,
          custom: {
            method: () =>
              this.timeCreateList[1].visitCnt,
            message: "내장인원수를 입력해주세요.",
          },
        },
        visitCnt3: {
          required: true,
          custom: {
            method: () =>
              this.timeCreateList[2].visitCnt,
            message: "내장인원수를 입력해주세요.",
          },
        },
      };
    },
  },
  created: function () {
    this.timeCreatePopupOption.resveDateFrom = getFormattedDate(new Date());
    this.timeCreatePopupOption.resveDateTo = getFormattedDate(new Date());


    // 요일
    this.timeCreatePopupOption.dwCodeOptions = commonCodesGetCommonCode(
        "DW_CODE"
    );

  },
  mounted: function () {
    this.timeCreatePopupSetting.bsnCodeOptions = _.orderBy(
      commonCodesGetCommonCode("BSN_CODE",true)?.filter(
        ({ comCode }) => comCode !== "CLOSE"
      ) || [],
      "sortNo"
    );
    if (
      this.timeCreatePopupSetting.bsnCodeOptions.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.timeCreatePopupSetting.bsnCodeOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.timeCreatePopupOption.bsnCode = "ALL";

    this.timeCreatePopupSetting.holeDivOptions = commonCodesGetCommonCode(
      "HOLE_DIV"
    );

    this.timeCreatePopupSetting.courseOptions = commonCodesGetCommonCode(
      "COURSE_CODE"
    ).filter((course)=>this.filterCourse.includes(course.comCode));
    if (
      this.timeCreatePopupSetting.courseOptions.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.timeCreatePopupSetting.courseOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    const teeOffInterval0 = this.timeCreateList[0].teeOffIntervalList.join(",");
    this.getTimeSetting(
      this.timeCreateList[0].startTime,
      teeOffInterval0,
      this.timeCreateList[0].teamCnt,
      null,
      0
    );

    const teeOffInterval1 = this.timeCreateList[1].teeOffIntervalList.join(",");
    this.getTimeSetting(
      this.timeCreateList[1].startTime,
      teeOffInterval1,
      this.timeCreateList[1].teamCnt,
      null,
      1
    );

    const teeOffInterval2 = this.timeCreateList[2].teeOffIntervalList.join(",");
    this.getTimeSetting(
      this.timeCreateList[2].startTime,
      teeOffInterval2,
      this.timeCreateList[2].teamCnt,
      null,
      2
    );
  },
  methods: {
    validateFormRefs,
    validateFormRefsClear,
    onCheckFlagClick() {
      for (let i = 0; i < 3; i++) {
        this.timeCreateList[i].checkFlag = !this.allCheckFlag;
      }
    },
    onStartTimeChange(args, row) {
      if (args.data === null) {
        this.timeCreateList[row].startTime = null;
      } else {
        this.timeCreateList[row].startTime = args.data;
      }

      this.teamCntChange(args, row);
    },
    dwValueCheckAllCheck: function () {
      if (this.dwInfo.dwValueCheckAll === true) {
        this.dwInfo.dwValue = ["1", "2", "3", "4", "5", "6", "7"];
      } else {
        this.dwInfo.dwValue = [];
      }
    },
    onEndTimeChange(args, row) {
      if (!args.data) {
        this.timeCreateList[row].endTime = null;
        return;
      }
      const teeOffInterval = this.timeCreateList[row].teeOffIntervalList.join(
        ","
      );
      this.getTimeSetting(
        this.timeCreateList[row].startTime,
        teeOffInterval,
        null,
        this.timeCreateList[row].endTime,
        row
      );
    },
    onReservationTimeCreatePopupClosed() {
      this.$emit("popupClosed");
    },
    showReservationTimeCreatePopup(resveDateFrom, resveDateTo, bsnCode) {
      this.timeCreatePopupOption.resveDateFrom = resveDateFrom;
      this.timeCreatePopupOption.resveDateTo = resveDateTo;
      this.timeCreatePopupOption.bsnCode = bsnCode;
      this.$refs.reservationTimeCreatePopup.show();
    },
    closeReservationTimeCreatePopup: function () {
      this.$refs.reservationTimeCreatePopup.hide();
    },
    saveReservationTimeCreatePopup: async function () {
      if (!this.saveButtonEnabledFlag) {
        return;
      }

      this.saveButtonEnabledFlag = false;

      // TODO : 필수 입력 항목은 모르기 때문에, 입력 validation은 나중에 추가
      let checkCnt = 0;
      let timeList = [];
      let a = 0;
      for (let i = 0; i < this.timeCreateList.length; i++) {
        if (!this.isVisiblePartDiv(this.timeCreateList[i].partDiv)) {
          continue;
        }
        if (this.timeCreateList[i].checkFlag === true) {
          if (this.timeCreateList[i].startTime === null) {
            this.errorToast(this.$t("main.popupMessage.noRequiredData"));
            this.saveButtonEnabledFlag = true;

            return;
          }
          timeList[a] = this.timeCreateList.slice(i, i + 1)[0];

          if(this.courseDiv === "%" && timeList[a].courseOption === "ALL") {
            timeList[a].courseList = null;
            timeList[a].course = null;
          }else if(this.courseDiv !== "%" && timeList[a].courseOption === "ALL") {
            timeList[a].course = null;
            timeList[a].courseList = this.filterCourse;
          }else if(timeList[a].courseOption !== "ALL") {
            timeList[a].courseList = null;
            timeList[a].course = timeList[a].courseOption;
          }
          for (let z = 0; z < timeList[a].teeOffIntervalList.length; z++) {
            if (timeList[a].teeOffIntervalList[z] < 0) {
              this.errorToast("티오프 간격에 음수는 입력하실 수 없습니다");
              this.saveButtonEnabledFlag = true;

              return;
            }

            if (
              timeList[a].teeOffIntervalList[z] === null ||
              timeList[a].teeOffIntervalList[z] === 0
            ) {
              timeList[a].teeOffIntervalList.splice(z, 1);
              z--;
            }
          }
          if (timeList[a].teeOffIntervalList.length === 0) {
            this.errorToast(this.$t("main.popupMessage.noRequiredData"));
            this.saveButtonEnabledFlag = true;

            return;
          }
          checkCnt++;
          a++;
        }
      }
      if (checkCnt === 0) {
        this.errorToast("생성할 타임을 선택해 주시기 바랍니다");
        this.saveButtonEnabledFlag = true;

        return;
      }
      // 요일검사
      if (!(this.dwInfo.dwValue.length > 0)) {
        this.errorToast("요일구분을 선택해 주십시오.");
        this.saveButtonEnabledFlag = true;
        return;
      }

      // TODO : API 호출
      const data = {
        resveDateFrom: this.timeCreatePopupOption.resveDateFrom,
        resveDateTo: this.timeCreatePopupOption.resveDateTo,
        dwCodeList: this.dwInfo.dwValue,
        bsnCode:
          this.timeCreatePopupOption.bsnCode === "ALL"
            ? null
            : this.timeCreatePopupOption.bsnCode,
        timeCreateList: timeList,
      };

      if (data.timeCreateList.length > 1) {
        if (
          data.timeCreateList[0].endTime >= data.timeCreateList[1].startTime
        ) {
          this.errorToast(
            "시작시간과 종료시간이 정상적이지 않습니다. 확인해 주시기 바랍니다"
          );
          this.saveButtonEnabledFlag = true;

          return;
        }

        if (data.timeCreateList.length > 2) {
          if (
            data.timeCreateList[1].endTime >= data.timeCreateList[2].startTime
          ) {
            this.errorToast(
              "시작시간과 종료시간이 정상적이지 않습니다. 확인해 주시기 바랍니다"
            );
            this.saveButtonEnabledFlag = true;

            return;
          }
        }
      }
      console.log(JSON.stringify(data, null, 2));

      await this.addTimeTable(data);

      this.saveButtonEnabledFlag = true;
    },
    validate() {
      return this.validateFormRefs(this.reservationTimeCreationValidator);
    },
    teamCntChange(args, row) {
      const teeOffInterval = this.timeCreateList[row].teeOffIntervalList.join(
        ","
      );
      this.getTimeSetting(
        this.timeCreateList[row].startTime,
        teeOffInterval,
        this.timeCreateList[row].teamCnt,
        null,
        row
      );
    },
    async addTimeTable(data) {
      if (!this.validate()) {
        return;
      }

      if ( 0 < (data.timeCreateList?.filter(({ teamCnt }) => teamCnt === 0) || []).length ) {
        this.errorToast("팀 수를 입력해주세요");
        return;
      }

      if (
        0 <
        (data.timeCreateList?.filter(({ visitCnt }) => visitCnt < 1 || visitCnt > 5) || [])
          .length
      ) {
        this.errorToast("내장인원수는 1부터 5까지 입력해야 합니다");
        return;
      }

      await addTimeTable(data)
        .then((response) => {
          console.log("addTimeTable.===>", response);
          this.infoToast("타임 생성이 완료되었습니다");
          this.$emit("popupClosed", { isReload: true });
          this.$refs.reservationTimeCreatePopup.hide();
        })
        .catch((error) => {
          console.log("addTimeTable.err.===>", error);
        });
    },
    getTimeSetting(startTime, teeOffInterval, teamCnt, endTime, row) {
      getTimeSetting(startTime, teeOffInterval, teamCnt, endTime, true)
        .then((response) => {
          console.log("getTimeSetting.===>", response);
          this.timeCreateList[row].endTime =
            response.value.timeSetting.maxResveTime;
          // if (!teamCnt && this.timeCreateList[row].teamCnt !== response.value.timeSetting.teamCnt) {
          //   this.timeCreateList[row].teamCnt = response.value.timeSetting.teamCnt;
          // }
          // if (!endTime && this.timeCreateList[row].endTime !== response.value.timeSetting.maxResveTime) {
          //   this.timeCreateList[row].endTime = response.value.timeSetting.maxResveTime;
          // }
        })
        .catch((error) => {
          console.log("getTimeSetting.err.===>", error);
        });
    },
    isVisiblePartDiv(partDiv) {
      const part = commonCodesGetCommonCode("PART_DIV").find(
        (item) => item.comCode === String(partDiv)
      );
      return part ? part.useFlag : false;
    },
  },
};
</script>

<template>
  <div>
    <ejs-dialog
        ref="webTimeSettingPopup"
        :header="'웹타임 설정'"
        :allowDragging="true"
        :showCloseIcon="true"
        :width="w"
        :height="h"
        :animationSettings="{ effect: 'None' }"
        enableResize="true"
        :isModal="true"
        :close="onWebTimeSettingPopupClosed"
    >
      <div class="window webTimeSettings">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">예약일자</div>
                    <ul class="content">
                      <li class="item dateRange">
                        <input-date-range
                            v-model="reservationDateRange"
                            @change="getTimeTable"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">시간</div>
                    <ul class="content">
                      <li class="item time">
                        <input-time
                          v-model="webTimeSettingOption.resveTimeFrom"
                          :format="'HH:mm'"
                          @change="onChangeResveTimeFrom"
                        />
                      </li>
                      <li class="item">~</li>
                      <li class="item time">
                        <input-time
                          v-model="webTimeSettingOption.resveTimeTo"
                          :format="'HH:mm'"
                          @change="onChangeResveTimeTo"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field businessCategory">
                    <div class="title">영업구분</div>
                    <ul class="content">
                      <li class="item">
                        <ejs-dropdownlist
                            ref="bsnCodeDropdown"
                            v-model="webTimeSettingOption.bsnCode"
                            :dataSource="webTimeSettingOption.bsnCodeOptions"
                            :allowFiltering="false"
                            :fields="{ text: 'comName', value: 'comCode' }"
                            :tabindex="-1"
                            cssClass="lookup-condition-dropdown"
                            @change="getTimeTable"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field businessCategory">
                    <div class="title">부구분</div>
                    <ul class="content">
                      <li class="item">
                        <ejs-dropdownlist
                            ref="partDivDropdown"
                            v-model="webTimeSettingOption.partDiv"
                            :dataSource="webTimeSettingOption.partDivOptions"
                            :allowFiltering="false"
                            :fields="{ text: 'comName', value: 'comCode' }"
                            :tabindex="-1"
                            cssClass="lookup-condition-dropdown"
                            @change="getTimeTable"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field businessCategory">
                    <div class="title">타임구분</div>
                    <ul class="content">
                      <li class="item">
                        <ejs-dropdownlist
                          ref="timeDivDropdown"
                          v-model="webTimeSettingOption.timeDiv"
                          :dataSource="webTimeSettingOption.timeDivOptions"
                          :allowFiltering="false"
                          :fields="{ text: 'comName', value: 'comCode' }"
                          :tabindex="-1"
                          cssClass="lookup-condition-dropdown"
                          @change="getTimeTable"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <ul class="content">
                      <li class="item">
                        <ul class="check">
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                id="webTimeOpenFlag"
                                :tabindex="-1"
                                v-model="webTimeSettingOption.isWebOpenFlag"
                                value="true"
                                @change="getTimeTable"
                              />
                              <i></i>
                              <div class="label">웹타임 오픈 여부</div>
                            </label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      buttonDiv="GET"
                      @click.native="getTimeTable"
                      :isIconCustom="true"
                  >
                    조회
                  </erp-button>
                </div>
                <div class="section-header">
                  <div class="header-left">
                    <ul class="header-label">
                      <li class="field">
                        <div class="title">홀구분</div>
                        <ul class="content">
                          <li class="item">
                            <i
                                v-bind:style="{
                                  'background-color': commonCodesGetColorValue(
                                    'HOLE_DIV',
                                    '9'
                                  ),
                                }"
                            ></i>
                            <div class="label">9홀 이하</div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div
                      class="section-body"
                      style="display: flex"
                  >
                    <div
                      class="body-box"
                      v-for="(
                        webTimeTableCourse, webTimeTableCourseIdx
                      ) in webTimeTableLists"
                      :key="webTimeTableCourseIdx"
                      :ref="`resveCourseCode${webTimeTableCourse.courseCode}`"
                      :id="webTimeTableCourseIdx"
                      :style="{ width: `calc(100% / ${filterCourse.length})` }"
                      :class="courseActiveIndex === webTimeTableCourseIdx ? 'border-box' : ''"
                      @click="courseDivClick(webTimeTableCourseIdx)"
                    >
<!--                      기존 column 주석처리 headerText 를 한줄로 사용하려고 기존로직 주석 후 커스텀 적용-->
<!--                          :columns="webTimeTableGridColumns(webTimeTableCourseIdx)"-->
                      <div class="body-header">
                        {{ webTimeTableLists[webTimeTableCourseIdx].courseName }}
                      </div>
                      <ejs-grid-wrapper
                          ref="webTimeTableGrid"
                          :provides="webTimeTableGridProvides"
                          :dataSource="webTimeTableCourse.webTimeTables"
                          :columns="webTimeGridColumn"
                          :editSettings="webTimeTableGridEditSettings"
                          :noColumnWidth="40"
                          :allowSorting="true"
                          :allowFiltering="true"
                          :isCustomizeKeyboardAction="true"
                          @queryCellInfo="webTimeTableQueryCellInfo"
                          @headerCellInfo="webTimeTableHeaderCellInfo"
                          @cellEdit="cellEditEvent"
                          @headerSelectAllCheckboxClicked="onHeaderSelectAllCheckboxClicked($event, webTimeTableCourseIdx)"
                          @gridCheckboxChanged="onGridCheckboxChanged($event, webTimeTableCourseIdx)"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  buttonDiv="SAVE"
                  :isShortcutButton="true"
                  :isIconCustom="true"
                  @click.native="saveButtonClicked"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="closeWebTimeSettingPopup"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .section-header {margin: 0}
body .section-header .header-label {margin: 0 -5px;padding: 3px 0 0}
body .content-body .article-section.section-01 .body-header {box-sizing: border-box; height: 24px; padding: 3px 8px 2px 8px; border: 1px solid #ccc; border-bottom: none; background-color: #f9f9f9; color: #000; text-align: center;}
body .content-body .article-section.section-01 .body-grid >>> .e-grid { height: calc(100% - 24px) !important;}
.border-box { border: 2px solid #1f5b41 !important; box-sizing: border-box; z-index: 10; }
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import ejsGridWrapper from '@/components/common/EjsGridWrapper.vue';
import {Edit, Filter, Sort, Resize, ForeignKey} from '@syncfusion/ej2-vue-grids';
import {MultiSelect, CheckBoxSelection} from '@syncfusion/ej2-dropdowns';
import {
  commonCodesGetColorValue, commonCodesGetCommonCode,
  commonCodesGetCommonCodeByAttrb,
  commonCodesGetComName,
} from '@/utils/commonCodes';
import {getFormattedDate} from '@/utils/date';
import commonMixin from '@/views/layout/mixin/commonMixin';
import {getTimeTable} from '@/api/timeTable';
import InputDateRange from '@/components/common/datetime/InputDateRange';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import InputTime from "@/components/common/datetime/InputTime";

MultiSelect.Inject(CheckBoxSelection);

export default {
  name: 'webTimeSettingPopup',
  components: {
    InputTime,
    InputDateRange,
    ejsGridWrapper,
    ErpButton
  },
  props:['filterCourse'],
  mixins: [commonMixin, confirmDialogMixin],
  computed: {
    reservationDateRange: {
      get() {
        return {
          from: this.inputDateRangeValue.from,
          to: this.inputDateRangeValue.to,
        };
      },
      set(reservationDateRange) {
        this.inputDateRangeValue.from = reservationDateRange.from;
        this.inputDateRangeValue.to = reservationDateRange.to;
      },
    },
    courseCodes() {
      return commonCodesGetCommonCode("COURSE_CODE", true);
    },
    w() {
      return this.isFullscreen ? 'calc(100% - 20px)' : 1180;
    },
    h() {
      return this.isFullscreen ? '100%' : 800;
    },
  },
  data() {
    return {
      courseActiveIndex : -1,
      isSaved: false,
      webTimeTableGridProvides: [Edit, Filter, Sort, Resize, ForeignKey],
      webTimeTableLists: [],
      webTimeSettingOption: {
        isPriceView: null,
        resveDateFromDay: null,
        resveDateToDay: null,
        bsnCodeOptions: commonCodesGetCommonCode("BSN_CODE", true),
        bsnCode: "%",
        partDivOptions: commonCodesGetCommonCode("PART_DIV", true),
        partDiv: "%",
        resveTimeFrom: null,
        resveTimeTo: null,
        timeDiv: "%",
        timeDivOptions: commonCodesGetCommonCode("TIME_DIV", true),
        isWebOpenFlag: false,
      },
      webTimeGridColumn: [
          {
            field: 'timeId',
            type: 'number',
            allowEditing: false,
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: 'resveDateByYYMMDD',
            headerText: '일자',
            type: 'string',
            allowEditing: false,
            minWidth: 16,
            width: 50,
            isDateType: true,
            textAlign: "center",
          },
          {
            field: 'partDiv',
            headerText: '부',
            type: 'string',
            allowEditing: false,
            textAlign: 'center',
            minWidth: 16,
            width: 40,
            isCommonCodeField: true,
            groupCode: 'PART_DIV',
          },
          {
            field: 'resveTime',
            headerText: '시간',
            type: 'string',
            allowEditing: false,
            minWidth: 16,
            width: 48,
          },
          {
            field: 'timeStatus',
            headerText: '타임상태',
            type: 'string',
            allowEditing: false,
            minWidth: 16,
            width: 66,
            isCommonCodeField: true,
            groupCode: 'TIME_STATUS',
          },
          {
            field: 'webOpenFlag',
            headerText: '웹',
            editType: 'booleanedit',
            type: 'boolean',
            displayAsCheckBox: true,
            textAlign: 'center',
            minWidth: 16,
            width: 60,
            isSelectAllColumn: true,
          },
          {
            field: 'agncyOpenFlag',
            headerText: '대행',
            editType: 'booleanedit',
            type: 'boolean',
            displayAsCheckBox: true,
            textAlign: 'center',
            minWidth: 16,
            width: 60,
            isSelectAllColumn: true,
            //selectAllCheckFunction: (record) => record.webOpenFlag,
          },
          {
            field: 'resveChannel',
            headerText: '대행사 리스트',
            visible: false,
          },
          {
            field: 'resveChannelList',
            headerText: '대행사 리스트',
            type: 'string',
            width: 240,
            edit: {
              create: () => {
                this.elem = document.createElement('input');
                return this.elem;
              },
              read: () => {
                return this.msObject.value;
              },
              destroy: () => {
                this.msObject.destroy();
              },
              write: (args) => {
                this.msObject = new MultiSelect({
                  dataSource: this.webTimeTableGrid.resveChannelOption,
                  fields: {text: 'comName', value: 'comCode'},
                  value: args.rowData.resveChannelList,
                  /*mode: "CheckBox", // selectAll을 위해서
                  allowFiltering: false,
                  showSelectAll: true // selectAll을 위해서*/
                });
                this.msObject.appendTo(this.elem);
              },
            },
            allowFiltering: false,
          },
          {
            field: 'resveChannelName',
            headerText: '대행사 리스트',
            visible: false,
          },
          {
            field: 'coprtnOpenFlag',
            headerText: '제휴',
            editType: 'booleanedit',
            type: 'boolean',
            displayAsCheckBox: true,
            textAlign: 'center',
            minWidth: 16,
            width: 60,
            isSelectAllColumn: true,
            selectAllCheckFunction: (record) => record.webOpenFlag,
          },
          {
            field: 'webOpenDt',
            allowEditing: false,
            type: 'string',
            headerText: '웹오픈일시',
            minWidth: 16,
            width: 125,
            textAlign: "center",
          },
          {
            field: 'webEndDt',
            allowEditing: false,
            type: 'string',
            headerText: '웹마감일시',
            minWidth: 16,
            width: 125,
            textAlign: "center",
          },
          {
            field: 'selfOpenFlag',
            allowEditing: false,
            headerText: '셀프',
            editType: 'booleanedit',
            type: 'boolean',
            displayAsCheckBox: true,
            textAlign: 'center',
            minWidth: 16,
            width: 41,
          },
          {
            field: 'joinOpenFlag',
            allowEditing: false,
            headerText: '조인',
            editType: 'booleanedit',
            type: 'boolean',
            displayAsCheckBox: true,
            textAlign: 'center',
            minWidth: 16,
            width: 41,
          },
          {
            field: 'holeDiv',
            headerText: '홀',
            type: 'string',
            allowEditing: false,
            minWidth: 16,
            width: 54,
            isCommonCodeField: true,
            groupCode: 'HOLE_DIV',
          },
          {
            field: 'timeDiv',
            headerText: '타임구분',
            type: 'string',
            allowEditing: true,
            minWidth: 16,
            width: 66,
            editType: "dropdownedit",
            isCommonCodeField: true,
            groupCode: 'TIME_DIV',
          },
        ],
      webTimeTableGrid: {
        resveChannelOption: commonCodesGetCommonCodeByAttrb(
            'RESVE_CHANNEL',
            'AGENT',
            true,
        ),
        resveChannelOptionComCodeArray: commonCodesGetCommonCodeByAttrb(
            'RESVE_CHANNEL',
            'AGENT',
            true,
        ).map((commonCode) => commonCode.comCode),
      },
      webTimeTableGridEditSettings: {
        allowEditing: true,
        allowAdding: false,
        allowDeleting: false,
        mode: 'Batch',
        showConfirmDialog: false,
      },
      inputDateRangeValue: {to: null, from: null},
      isFullscreen: true,
    };
  },
  created() {
    this.webTimeSettingOption.resveDateFromDay = commonCodesGetComName(
        'DW_CODE',
        String(new Date().getDay() + 1),
    );
    this.webTimeSettingOption.resveDateToDay = commonCodesGetComName(
        'DW_CODE',
        String(new Date().getDay() + 1),
    );
    if (
        this.webTimeSettingOption.bsnCodeOptions.findIndex(
            (obj) => obj.comCode === "ALL"
        ) === -1
    ) {
      this.webTimeSettingOption.bsnCodeOptions.unshift({
        comCode: "%",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
    if (
        this.webTimeSettingOption.partDivOptions.findIndex(
            (obj) => obj.comCode === "ALL"
        ) === -1
    ) {
      this.webTimeSettingOption.partDivOptions.unshift({
        comCode: "%",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
    if (this.webTimeSettingOption.timeDivOptions.findIndex((obj) => obj.comCode === "ALL") === -1) {
      this.webTimeSettingOption.timeDivOptions.unshift({
        comCode: "%",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
  },
  async mounted() {
    await this.$nextTick();
    const $el = document.getElementById('_dialog-header');
    const $copyEl = $el.firstElementChild.cloneNode(true);
    $copyEl.id = 'custom-fullscreen';
    $copyEl.classList.add('fullscreen');
    $copyEl.setAttribute('title', 'Fullscreen');
    $copyEl.setAttribute('aria-title', 'Fullscreen');
    $copyEl.addEventListener('click', () => this.onToggleScreenSizeClick());
    $copyEl.removeEventListener('click', () => this.onToggleScreenSizeClick());
    $el.insertBefore($copyEl, $el.lastChild);
  },
  methods: {
    commonCodesGetColorValue,
    onWebTimeSettingPopupClosed() {
      this.$emit('popupClosed', {isReload: this.isSaved});
    },
    showWebTimeSettingPopup(resveDateFrom, resveDateTo = null, isPriceView = null) {
      this.webTimeSettingOption.isPriceView = isPriceView;
      this.$refs.webTimeSettingPopup.show();
      this.inputDateRangeValue.from = resveDateFrom || getFormattedDate(new Date());
      this.inputDateRangeValue.to = resveDateTo || getFormattedDate(new Date());
      this.getTimeTable();
    },
    closeWebTimeSettingPopup() {
      this.$refs.webTimeSettingPopup.hide();
    },
    courseDivClick(courseIdx) {
      this.courseActiveIndex = courseIdx;
    },
    // webTimeTableGridColumns(webTimeTableCourseIdx) {
    //   return [
    //     {
    //       field: 'timeId',
    //       type: 'number',
    //       allowEditing: false,
    //       isPrimaryKey: true,
    //       visible: false,
    //     },
    //     {
    //       headerText: this.webTimeTableLists[webTimeTableCourseIdx].courseName,
    //       columns: [
    //         {
    //           field: 'resveDateByYYMMDD',
    //           headerText: '일자',
    //           type: 'string',
    //           allowEditing: false,
    //           minWidth: 16,
    //           width: 50,
    //           isDateType: true,
    //           textAlign: "center",
    //         },
    //         {
    //           field: 'partDiv',
    //           headerText: '부',
    //           type: 'string',
    //           allowEditing: false,
    //           textAlign: 'center',
    //           minWidth: 16,
    //           width: 40,
    //           isCommonCodeField: true,
    //           groupCode: 'PART_DIV',
    //         },
    //         {
    //           field: 'resveTime',
    //           headerText: '시간',
    //           type: 'string',
    //           allowEditing: false,
    //           minWidth: 16,
    //           width: 48,
    //         },
    //         {
    //           field: 'timeStatus',
    //           headerText: '타임상태',
    //           type: 'string',
    //           allowEditing: false,
    //           minWidth: 16,
    //           width: 66,
    //           isCommonCodeField: true,
    //           groupCode: 'TIME_STATUS',
    //         },
    //         {
    //           field: 'webOpenFlag',
    //           headerText: '웹',
    //           editType: 'booleanedit',
    //           type: 'boolean',
    //           displayAsCheckBox: true,
    //           textAlign: 'center',
    //           minWidth: 16,
    //           width: 60,
    //           isSelectAllColumn: true,
    //         },
    //         {
    //           field: 'agncyOpenFlag',
    //           headerText: '대행',
    //           editType: 'booleanedit',
    //           type: 'boolean',
    //           displayAsCheckBox: true,
    //           textAlign: 'center',
    //           minWidth: 16,
    //           width: 60,
    //           isSelectAllColumn: true,
    //           selectAllCheckFunction: (record) => record.webOpenFlag,
    //         },
    //         {
    //           field: 'resveChannel',
    //           headerText: '대행사 리스트',
    //           visible: false,
    //         },
    //         {
    //           field: 'resveChannelList',
    //           headerText: '대행사 리스트',
    //           type: 'string',
    //           width: 240,
    //           edit: {
    //             create: () => {
    //               this.elem = document.createElement('input');
    //               return this.elem;
    //             },
    //             read: () => {
    //               return this.msObject.value;
    //             },
    //             destroy: () => {
    //               this.msObject.destroy();
    //             },
    //             write: (args) => {
    //               this.msObject = new MultiSelect({
    //                 dataSource: this.webTimeTableGrid.resveChannelOption,
    //                 fields: {text: 'comName', value: 'comCode'},
    //                 value: args.rowData.resveChannelList,
    //                 /*mode: "CheckBox", // selectAll을 위해서
    //                 allowFiltering: false,
    //                 showSelectAll: true // selectAll을 위해서*/
    //               });
    //               this.msObject.appendTo(this.elem);
    //             },
    //           },
    //           allowFiltering: false,
    //         },
    //         {
    //           field: 'resveChannelName',
    //           headerText: '대행사 리스트',
    //           visible: false,
    //         },
    //         {
    //           field: 'coprtnOpenFlag',
    //           headerText: '제휴',
    //           editType: 'booleanedit',
    //           type: 'boolean',
    //           displayAsCheckBox: true,
    //           textAlign: 'center',
    //           minWidth: 16,
    //           width: 60,
    //           isSelectAllColumn: true,
    //           selectAllCheckFunction: (record) => record.webOpenFlag,
    //         },
    //         {
    //           field: 'webOpenDt',
    //           allowEditing: false,
    //           type: 'string',
    //           headerText: '웹오픈일시',
    //           minWidth: 16,
    //           width: 125,
    //           textAlign: "center",
    //         },
    //         {
    //           field: 'webEndDt',
    //           allowEditing: false,
    //           type: 'string',
    //           headerText: '웹마감일시',
    //           minWidth: 16,
    //           width: 125,
    //           textAlign: "center",
    //         },
    //         {
    //           field: 'selfOpenFlag',
    //           allowEditing: false,
    //           headerText: '셀프',
    //           editType: 'booleanedit',
    //           type: 'boolean',
    //           displayAsCheckBox: true,
    //           textAlign: 'center',
    //           minWidth: 16,
    //           width: 41,
    //         },
    //         {
    //           field: 'joinOpenFlag',
    //           allowEditing: false,
    //           headerText: '조인',
    //           editType: 'booleanedit',
    //           type: 'boolean',
    //           displayAsCheckBox: true,
    //           textAlign: 'center',
    //           minWidth: 16,
    //           width: 41,
    //         },
    //         {
    //           field: 'holeDiv',
    //           headerText: '홀',
    //           type: 'string',
    //           allowEditing: false,
    //           minWidth: 16,
    //           width: 54,
    //           isCommonCodeField: true,
    //           groupCode: 'HOLE_DIV',
    //         },
    //         {
    //           field: 'timeDiv',
    //           headerText: '타임구분',
    //           type: 'string',
    //           allowEditing: true,
    //           minWidth: 16,
    //           width: 66,
    //           editType: "dropdownedit",
    //           isCommonCodeField: true,
    //           groupCode: 'TIME_DIV',
    //         },
    //       ],
    //     },
    //   ];
    // },
    async onToggleScreenSizeClick() {
      this.isFullscreen = !this.isFullscreen;
      await this.$nextTick();
      const $el = document.getElementById('custom-fullscreen');
      $el.classList.remove(!this.isFullscreen ? 'fullscreen' : 'notfullscreen');
      $el.classList.add(this.isFullscreen ? 'fullscreen' : 'notfullscreen');
    },
    // saveWebTimeSettingPopup: function () {
    //   if(!this.$refs.webTimeTableGrid.validate()) {
    //     return;
    //   }
    //   const data = this.$refs.webTimeTableGrid.getBatchChanges();
    //   this.saveWebTimeSetting(data);
    // },
    webTimeTableQueryCellInfo(args) {
      const {column: {field}, data} = args;
      if (
          field === 'webOpenFlag' ||
          field === 'agncyOpenFlag' ||
          field === 'resveChannel' ||
          field === 'coprtnOpenFlag'
      ) {
        args.cell.classList.add(this.$t('className.grid.modifyArea'));
      } else if (args.column.field === 'holeDiv') {
        if (args.foreignKeyData[0].codeAbrv <= 9) {
          args.cell.style.backgroundColor = this.commonCodesGetColorValue(
              'HOLE_DIV',
              args.data.holeDiv,
          );
        }
      }
      if (data.divFlag) {
        args.cell.classList.add(
            this.$t("className.grid.devReservationPartDivLine")
        );
      }
    },
    webTimeTableHeaderCellInfo(args) {
      if (args.cell.column.headerText === 'NO') {
        args.node.classList.add(this.$t('className.grid.noSortFilter'));
      }
    },
    getTimeTable() {
      const isPriceView = this.webTimeSettingOption.isPriceView;
      const bsnCode = this.webTimeSettingOption.bsnCode !== "%"
        ? this.webTimeSettingOption.bsnCode
        : null;
      const partDiv = this.webTimeSettingOption.partDiv !== "%"
          ? this.webTimeSettingOption.partDiv
          : null;
      const resveCourse = null;
      const resveDateFrom = this.inputDateRangeValue.from;
      const resveDateTo = this.inputDateRangeValue.to;
      const resveTimeFrom = this.webTimeSettingOption.resveTimeFrom;
      const resveTimeTo = this.webTimeSettingOption.resveTimeTo;
      const timeStatus = 'EMPTY';
      const timeDiv = this.webTimeSettingOption.timeDiv !== "%" ? this.webTimeSettingOption.timeDiv : null;
      const isWebOpenFlag = this.webTimeSettingOption.isWebOpenFlag;

      getTimeTable(
          isPriceView,
          bsnCode,
          partDiv,
          resveCourse,
          resveDateFrom,
          resveDateTo,
          resveTimeFrom,
          resveTimeTo,
          timeStatus,
          timeDiv,
          null,
          null,
          null,
          null,
          null,
          isWebOpenFlag,
      )
          .then((response) => {
            console.log('getTimeTable.===>', response);
            let resveDate = null;
            let webTimeTableLists = [];
            this.courseCodes
              .forEach(courseCode => webTimeTableLists.push({
                courseCode: courseCode.comCode,
                courseName: courseCode.comName,
                webTimeTables: response.value.timeTableList.filter(item => item.resveCourse === courseCode.comCode)
                  ?.map(item => {
                    if (resveDate !== item.resveDate) {
                      item.divFlag = !!resveDate;
                      resveDate = item.resveDate;
                    }
                    item.resveDateByYYMMDD = getFormattedDate(item.resveDate, "MM/DD");
                    item.webOpenDt = getFormattedDate(item.webOpenDt, "YYYY-MM-DD HH:mm:ss");
                    item.webEndDt = getFormattedDate(item.webEndDt, "YYYY-MM-DD HH:mm:ss");
                    return item;
                  }),
              }));
            this.webTimeTableLists = webTimeTableLists.filter((list)=>this.filterCourse.includes(list.courseCode));
            console.log(
                webTimeTableLists
            );
          })
          .catch((error) => {
            console.log('getTimeTable.err.===>', error);
          });
    },
    // saveWebTimeSetting (data) {
    //   if (data.changedRecords.length === 0) {
    //     this.errorToast(this.$t('main.popupMessage.noDataToSave'));
    //     return;
    //   }
    //
    //   // for (let i = 0; i < data.changedRecords.length; i++) {
    //   //  if (typeof data.changedRecords[i].resveChannel === 'string') {
    //   //    const arrayResveChannel = data.changedRecords[i].resveChannel.split(',')
    //   //    data.changedRecords[i].resveChannel = arrayResveChannel
    //   //  }
    //   // }
    //   // 웹타임설정시 웹타임이 체크 될 경우 resveRtrvlMethod = true
    //   for (let i = 0; i < data.changedRecords.length; i++) {
    //     if (data.changedRecords[i].webOpenFlag === true) {
    //       console.log('data.changedRecords.webOpenFlag===>', data.changedRecords[i].webOpenFlag);
    //       data.changedRecords[i].resveRtrvlMethod = 'WEB';
    //     } else {
    //       data.changedRecords[i].resveRtrvlMethod = 'TEL';
    //     }
    //   }
    //   console.log('data ===>', data);
    //   saveWebTimeSetting(data).then(response => {
    //     console.log('saveWebTimeSetting.===>', response);
    //     this.$emit('popupClosed', { isReload: true });
    //     this.$refs.webTimeSettingPopup.hide();
    //   }).catch(error => {
    //     console.log('saveWebTimeSetting.err.===>', error);
    //   });
    // },
    cellEditEvent(args) {
      if (
          (args.columnName === 'agncyOpenFlag' ||
              args.columnName === 'coprtnOpenFlag') &&
          !args.rowData.webOpenFlag
      ) {
        // 웹이 체크되어 있어야만 대행이 편집 가능
        // this.errorToast('웹을 먼저 선택해 주세요');
        // args.cancel = true;
      } else if (
          args.columnName === 'resveChannelList' &&
          //(!args.rowData.webOpenFlag || !args.rowData.agncyOpenFlag)
          !args.rowData.agncyOpenFlag
      ) {
        // 웹 및 대행이 체크되어 있어야만 대행사 리스트가 편집 가능
        args.cancel = true;
      }
    },
    onHeaderSelectAllCheckboxClicked(args, gridIdx) {
      // if (args.field === 'webOpenFlag') {
      //   // 대행의 전체 선택 체크박스 상태 변경
      //   //this.$refs.webTimeTableGrid[gridIdx].setSelectAllColumnCheckbox('agncyOpenFlag');
      //
      //   // 대행 check 해제 및 대행사 리스트 해제
      //   if (!args.value) {
      //     const batchCurrentViewRecords = this.$refs.webTimeTableGrid[gridIdx].getBatchCurrentViewRecords();
      //     batchCurrentViewRecords.forEach((record, idx) => {
      //       if (record['agncyOpenFlag']) {
      //         this.$refs.webTimeTableGrid[gridIdx].updateCell(
      //             idx,
      //             'agncyOpenFlag',
      //             false,
      //         );
      //         this.$refs.webTimeTableGrid[gridIdx].updateCell(idx, 'resveChannel', null);
      //         this.$refs.webTimeTableGrid[gridIdx].updateCell(
      //             idx,
      //             'resveChannelList',
      //             null,
      //         );
      //         this.$refs.webTimeTableGrid[gridIdx].updateCell(
      //             idx,
      //             'resveChannelName',
      //             null,
      //         );
      //         this.$refs.webTimeTableGrid[gridIdx].updateCell(
      //             idx,
      //             'coprtnOpenFlag',
      //             false,
      //         );
      //       }
      //     });
      //   }
      // } else
      if (args.field === 'agncyOpenFlag') {
        if (args.value) {
          args.valueChangedRowIndexes.forEach((rowIndex) => {
            // 대행사 선택
            this.$refs.webTimeTableGrid[gridIdx].updateCell(
                rowIndex,
                'resveChannelList',
                this.webTimeTableGrid.resveChannelOptionComCodeArray,
            );
          });
        } else {
          args.valueChangedRowIndexes.forEach((rowIndex) => {
            // 대행사 선택 해제
            this.$refs.webTimeTableGrid[gridIdx].updateCell(
                rowIndex,
                'resveChannelList',
                null,
            );
          });
        }
      }
    },
    onGridCheckboxChanged(args, gridIdx) {
      const col = args.columnName;
      const data = args.value;
      const timeId = args.rowData.timeId;
      const rowIndex = this.$refs.webTimeTableGrid[gridIdx].getRowIndexByPrimaryKey(
          timeId,
      );

      // if (col === 'webOpenFlag') {
      //   // "웹" 컬럼 값 변경 시, "대행" 컬럼의 전체 선택 체크박스 값 변경
      //   this.$refs.webTimeTableGrid[gridIdx].setSelectAllColumnCheckbox(
      //       'agncyOpenFlag',
      //       args.rowIndex,
      //       args.columnName,
      //       args.value,
      //   );
      //
      //   // 웹 체크를 풀면 대행 및 대행사 리스트 clear
      //   if (!data) {
      //     this.$refs.webTimeTableGrid[gridIdx].updateCell(
      //         rowIndex,
      //         'agncyOpenFlag',
      //         false,
      //     );
      //     this.$refs.webTimeTableGrid[gridIdx].updateCell(
      //         rowIndex,
      //         'resveChannel',
      //         null,
      //     );
      //     this.$refs.webTimeTableGrid[gridIdx].updateCell(
      //         rowIndex,
      //         'resveChannelList',
      //         null,
      //     );
      //     this.$refs.webTimeTableGrid[gridIdx].updateCell(
      //         rowIndex,
      //         'resveChannelName',
      //         null,
      //     );
      //     this.$refs.webTimeTableGrid[gridIdx].updateCell(
      //         rowIndex,
      //         'coprtnOpenFlag',
      //         false,
      //     );
      //   }
      // } else
      if (col === 'agncyOpenFlag') {
        if (!data) {
          // 대행 체크를 풀면 대행사 리스트 clear
          this.$refs.webTimeTableGrid[gridIdx].updateCell(
              rowIndex,
              'resveChannel',
              null,
          );
          this.$refs.webTimeTableGrid[gridIdx].updateCell(
              rowIndex,
              'resveChannelList',
              null,
          );
          this.$refs.webTimeTableGrid[gridIdx].updateCell(
              rowIndex,
              'resveChannelName',
              null,
          );
        } else {
          // 대행 체크 시 대행사 값 전체 설정
          this.$refs.webTimeTableGrid[gridIdx].updateCell(
              rowIndex,
              'resveChannelList',
              this.webTimeTableGrid.resveChannelOptionComCodeArray,
          );
        }
      }
    },
    async saveButtonClicked() {
      let count = 0;
      let reservationWebTimes = [];
      this.$refs.webTimeTableGrid.forEach(grid => {
        grid.saveCell();
        const {
          changedRecords: webTimes,
        } = grid.getBatchChanges();
        count += webTimes.length;
        webTimes.forEach((webTime) => {
          const reservationWebTime = {
            timeId: webTime.timeId,
            optFlag: webTime.optFlag,
            resveDate: webTime.resveDate,
            timeDiv: webTime.timeDiv,
            webOpenFlag: webTime.webOpenFlag,
            agncyOpenFlag: webTime.agncyOpenFlag,
            // 웹타임 설정시 타임회수 데이터 변경할 이유 없음.
            // resveRtrvlMethod: 'TEL',
            coprtnOpenFlag: webTime.coprtnOpenFlag,
            reservationChannels:
                webTime.resveChannelList === null
                    ? null
                    : webTime.resveChannelList.map((resveChannel) => ({
                      timeId: webTime.timeId,
                      resveChannel: resveChannel,
                    })),
          };
          reservationWebTimes.push(reservationWebTime);
        });
      });
      if (!(count > 0)) {
        this.errorToast(this.$t('main.popupMessage.noDataToSave'));
        return;
      }
      this.infoToast(this.$t('main.popupMessage.saved'), { positionX: "Center" });
      console.log('reservationWebTimes');
      console.log(reservationWebTimes);
      console.log('reservationWebTimes');

      await GolfErpAPI.reservationWebTimesSetting(reservationWebTimes);
      this.webTimeTableLists = [];
      this.getTimeTable();
      this.isSaved = true;
    },
    onChangeResveTimeFrom() {
      if (this.webTimeSettingOption.resveTimeFrom === "00:00") {
        this.webTimeSettingOption.resveTimeFrom = null;
      }
    },
    onChangeResveTimeTo() {
      if (this.webTimeSettingOption.resveTimeTo === "00:00") {
        this.webTimeSettingOption.resveTimeTo = null;
      }
    }
  },
};
</script>

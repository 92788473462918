import request from "@/utils/request";

/**
 * @return Promise
 */
export function getTimeTable(
  isPriceView,
  bsnCode,
  partDiv,
  resveCourse,
  resveDateFrom,
  resveDateTo,
  resveTimeFrom,
  resveTimeTo,
  timeStatus,
  timeDiv,
  promotedOnly,
  isWebTimeView,
  isAgencyTimeView,
  isSelfTimeView,
  isJoinTimeView,
  isWebOpenFlag,
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/standard_info/time_table",
    method: "get",
    params: {
      bsnCode: bsnCode,
      partDiv: partDiv,
      resveCourse: resveCourse,
      resveDateFrom: resveDateFrom,
      resveDateTo: resveDateTo,
      resveTimeFrom: resveTimeFrom,
      resveTimeTo: resveTimeTo,
      timeStatus: timeStatus,
      timeDiv: timeDiv,
      promotedOnly: promotedOnly,
      isWebTimeView: isWebTimeView,
      isAgencyTimeView: isAgencyTimeView,
      isSelfTimeView: isSelfTimeView,
      isJoinTimeView: isJoinTimeView,
      isWebOpenFlag: isWebOpenFlag,
      isPriceView: isPriceView
    },
  });
}

/**
 * @return Promise
 */
export function getTimeSetting(
  startTime,
  teeOffInterval,
  teamCnt,
  endTime,
  isBackground = false
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/standard_info/time_table/time-setting",
    isBackground,
    method: "get",
    params: {
      startTime: startTime,
      teeOffInterval: teeOffInterval,
      teamCnt: teamCnt,
      endTime: endTime,
    },
  });
}

/**
 * @return Promise
 */
export function addTimeTable(data) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/standard_info/time_table",
    isBackground: true,
    method: "post",
    data,
  });
}

/**
 * @return Promise
 */
export function putTimeTable(data) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/standard_info/time_table/",
    method: "put",
    data,
  });
}

/**
 * @return Promise
 */
export function copyTimeTable(data) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/standard_info/time_table/copy",
    method: "post",
    data,
  });
}

/**
 * @return Promise
 */
export function getTimeTableByTimeStatus(
  bsnCode,
  partDiv,
  resveCourse,
  resveDateFrom,
  resveDateTo,
  resveTimeFrom,
  resveTimeTo,
  timeStatus
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/standard_info/time_table/" + timeStatus,
    method: "get",
    params: {
      bsnCode: bsnCode,
      partDiv: partDiv,
      resveCourse: resveCourse,
      resveDateFrom: resveDateFrom,
      resveDateTo: resveDateTo,
      resveTimeFrom: resveTimeFrom,
      resveTimeTo: resveTimeTo,
      timeStatus: timeStatus,
    },
  });
}

/**
 * @return Promise
 */
export function saveTimeStatusSetting(timeStatus, data) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/standard_info/time_table/" + timeStatus,
    method: "put",
    data,
  });
}

/**
 * @return Promise
 */
export function getDailyTimeStatus(resveDate, isBackground = true) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/standard_info/time_table/daily",
    method: "get",
    isBackground: isBackground,
    params: {
      resveDate: resveDate,
    },
  });
}

/**
 * @return Promise
 */
export function webTimeCancel(timeId, optFlag) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/standard_info/web_time_cancel",
    method: "put",
    params: {
      timeId: timeId,
      optFlag: optFlag,
    },
  });
}

/**
 * @return Promise
 */
export function getTimeStatusCount(timeId, timeStatus) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/standard_info/time_table/time-status-count",
    method: "get",
    params: {
      timeId: timeId,
      timeStatus: timeStatus,
    },
  });
}

<template>
  <div>
    <ejs-dialog
      ref="promotionSearchPopup"
      :header="`프로모션 검색`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="1180"
      height="600"
      :animationSettings="{ effect: 'None' }"
      enableResize="true"
      :isModal="true"
      :close="onPromotionSearchPopupClosed"
    >
      <div class="window promotionalSearch">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field promotionCategory">
                    <div class="title">프로모션 구분</div>
                    <ul class="content">
                      <li class="item">
                        <ejs-dropdownlist
                          ref="promtnDivDropdown"
                          v-model="promotionSearchOption.promtnDiv"
                          :dataSource="promotionSearchOption.promtnDivOptions"
                          :allowFiltering="false"
                          :fields="{ text: 'comName', value: 'comCode' }"
                          :tabindex="-1"
                          cssClass="lookup-condition-dropdown"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field promotionName">
                    <div class="title">프로모션명</div>
                    <ul class="content">
                      <li class="item">
                        <input-text
                          ref="promtnNameTextBox"
                          id="promtnNameTextBox"
                          v-model="promotionSearchOption.promtnName"
                          @keydown.enter="onPromtnNameKeyDownEnter"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <ul class="content">
                      <li class="item">
                        <ul class="check">
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                id="applyDate"
                                v-model="promotionSearchOption.applyDate"
                                value="true"
                              />
                              <i></i>
                              <div class="label">적용일자</div>
                            </label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul class="content">
                      <li class="item dateRange">
                        <input-date-range
                          type="lookup-condition"
                          v-model="applyDateRange"
                          :disabled="!promotionSearchOption.applyDate"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field businessCategory">
                    <div class="title">영업구분</div>
                    <ul class="content">
                      <li class="item">
                        <ejs-dropdownlist
                          ref="bsnCodeDropdown"
                          v-model="promotionSearchOption.bsnCode"
                          :dataSource="promotionSearchOption.bsnCodeOptions"
                          :allowFiltering="false"
                          :fields="{ text: 'comName', value: 'comCode' }"
                          cssClass="lookup-condition-dropdown"
                        ></ejs-dropdownlist>
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <ul class="content">
                      <li class="item">
                        <ul class="check">
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                id="useFlag"
                                v-model="promotionSearchOption.useFlag"
                                value="true"
                              />
                              <i></i>
                              <div class="label">사용</div>
                            </label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      buttonDiv="GET"
                      :isShortcutButton="true"
                      @click.native="onSearchPromotionClicked"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <ejs-grid-wrapper
                      ref="promotionGrid"
                      :columns="promotionGridColumns"
                      :provides="gridProvide"
                      :dataSource="promotionList"
                      :isInPopup="true"
                      :allowResizing="true"
                      @headerCellInfo="promotionGridHeaderCellInfo"
                      @rowSelected="promotionGridRowSelected"
                      @onGridDialogDoubleClickedOrEnterKeyed="
                        onPromotionGridDialogDoubleClickedOrEnterKeyed
                      "
                      @dataBound="onPromotionGridDataBound"
                    />
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  buttonDiv="SAVE"
                  :isIconCustom="true"
                  @click.native="promotionConfirm"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="closePromotionSearchPopup"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { Resize, ForeignKey } from "@syncfusion/ej2-vue-grids";

import ErpButton from "@/components/button/ErpButton.vue";
import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
} from "@/utils/commonCodes";
import { getFormattedDate } from "@/utils/date";
import commonMixin from "@/views/layout/mixin/commonMixin";
import moment from "moment";

import EjsGridWrapper from "@/components/common/EjsGridWrapper";

import { getPromotionList } from "@/api/promotion";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

export default {
  name: "promotionSearchPopup",
  components: {
    InputText,
    InputDateRange,
    EjsGridWrapper,
    ErpButton
  },
  mixins: [commonMixin, confirmDialogMixin],
  data: function () {
    return {
      popupData: {},
      selectedPromotion: {},
      promotionList: [],
      promotionSelectRtnData: {
        promtnDiv: null,
      },
      promotionSearchOption: {
        promtnDiv: null,
        promtnDivOptions: [],
        applyDate: false,
        applyDateFrom: null,
        applyDateTo: null,
        bsnCode: null,
        bsnCodeOptions: [],
        useFlag: true,
        promtnName: null,
      },
      promotionViewOptions: {
        promtnDivOptions: commonCodesGetCommonCode("PROMTN_DIV"),
        bsnCodeOptions: commonCodesGetCommonCode("BSN_CODE"),
        paymtCodeOptions: commonCodesGetCommonCode("PAYMT_CODE"),
      },
      dwValueTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template:
              "<div>" +
              (`{{test[1].ATTRB}}` !== "Y" ? `{{test[1].ATTRB}}` : "b") +
              "</div>",
            data: function () {
              return {
                data: {},
              };
            },
            computed: {
              test: function () {
                let obj = JSON.parse(this.data.dwValue);
                obj.sort(function (a, b) {
                  return a.IDX < b.IDX ? -1 : a.IDX > b.IDX ? 1 : 0;
                });
                console.log("obj.===>", obj);
                return obj;
              },
            },
          }),
        };
      },
      promotionGridFilterSettings: { type: "Menu" },
      dwValueColumns: [
        {
          field: "promtnId",
          isPrimaryKey: true,
          type: "number",
          visible: false,
        },
        {
          field: "dwValue1",
          headerText: "월",
          minWidth: "16",
          width: "30",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          textAlign: "Center",
          allowFiltering: false,
        },
        {
          field: "dwValue2",
          headerText: "화",
          minWidth: "16",
          width: "30",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          textAlign: "Center",
          allowFiltering: false,
        },
        {
          field: "dwValue3",
          headerText: "수",
          minWidth: "16",
          width: "30",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          textAlign: "Center",
          allowFiltering: false,
        },
        {
          field: "dwValue4",
          headerText: "목",
          minWidth: "16",
          width: "30",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          textAlign: "Center",
          allowFiltering: false,
        },
        {
          field: "dwValue5",
          headerText: "금",
          minWidth: "16",
          width: "30",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          textAlign: "Center",
          allowFiltering: false,
        },
        {
          field: "dwValue6",
          headerText: "토",
          minWidth: "16",
          width: "30",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          textAlign: "Center",
          allowFiltering: false,
        },
        {
          field: "dwValue7",
          headerText: "일",
          minWidth: "16",
          width: "30",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          textAlign: "Center",
          allowFiltering: false,
        },
      ],
      gridProvide: [Resize, ForeignKey],
    };
  },
  computed: {
    promotionGridColumns() {
      return [
        {
          field: "promtnDiv",
          allowEditing: false,
          headerText: "프로모션 구분",
          minWidth: 16,
          width: 120,
          textAlign: "left",
          editType: "dropdownedit",
          isCommonCodeField: true,
          dataSource: this.promotionViewOptions.promtnDivOptions,
        },
        {
          field: "promtnName",
          allowEditing: false,
          headerText: "프로모션 명",
          minWidth: 16,
          width: 150,
          textAlign: "left",
        },
        {
          field: "memberGrade",
          allowEditing: false,
          headerText: "회원등급",
          minWidth: 16,
          width: 170,
          textAlign: "left",
        },
        {
          field: "startEndDate",
          allowEditing: false,
          headerText: "적용일자",
          minWidth: 16,
          width: 160,
          textAlign: "left",
        },
        {
          field: "resveCourseName",
          allowEditing: false,
          headerText: "출발코스",
          minWidth: 16,
          width: 180,
          textAlign: "left",
        },
        {
          field: "startEndTime",
          allowEditing: false,
          headerText: "적용시간",
          minWidth: 16,
          width: 90,
          textAlign: "left",
        },
        {
          field: "bsnCode",
          allowEditing: false,
          headerText: "영업구분",
          minWidth: 16,
          width: 90,
          textAlign: "center",
          editType: "dropdownedit",
          isCommonCodeField: true,
          dataSource: this.promotionViewOptions.bsnCodeOptions,
        },
        {
          field: "dcMethod",
          allowEditing: false,
          headerText: "할인방법",
          minWidth: 16,
          width: 90,
          textAlign: "left",
          comCode: "DC_METHOD",
          valueAccessor: this.valueAccessor,
        },
        {
          field: "dcAmt",
          allowEditing: false,
          headerText: "할인금액/율",
          minWidth: 16,
          width: 110,
          textAlign: "right",
          isNumericType: true,
        },
        {
          field: "paymtCode",
          allowEditing: false,
          headerText: "요금코드",
          minWidth: 16,
          width: 120,
          textAlign: "left",
          editType: "dropdownedit",
          isCommonCodeField: true,
          dataSource: this.promotionViewOptions.paymtCodeOptions,
        },
        {
          allowEditing: false,
          headerText: "요일",
          columns: this.dwValueColumns,
          textAlign: "center",
        },
        {
          field: "useFlag",
          allowEditing: false,
          type: "boolean",
          headerText: "사용",
          minWidth: 16,
          width: 90,
          textAlign: "Center",
          editType: "booleanedit",
          displayAsCheckBox: true,
        },
      ];
    },
    applyDateRange: {
      get: function () {
        return {
          from: this.promotionSearchOption.applyDateFrom,
          to: this.promotionSearchOption.applyDateTo,
        };
      },
      set: function (applyDateRange) {
        this.promotionSearchOption.applyDateFrom = applyDateRange.from;
        this.promotionSearchOption.applyDateTo = applyDateRange.to;
      },
    },
  },
  created: function () {
    this.promotionSearchOption.applyDateFrom = getFormattedDate(new Date());
    this.promotionSearchOption.applyDateTo = getFormattedDate(new Date());
  },
  mounted: function () {
    this.promotionSearchOption.promtnDivOptions = commonCodesGetCommonCode(
      "PROMTN_DIV"
    );
    if (
      this.promotionSearchOption.promtnDivOptions.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.promotionSearchOption.promtnDivOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.promotionSearchOption.promtnDiv = "ALL";

    this.promotionSearchOption.bsnCodeOptions = commonCodesGetCommonCode(
      "BSN_CODE"
    );
    if (
      this.promotionSearchOption.bsnCodeOptions.findIndex(
        (obj) => obj.comCode === "%"
      ) === -1
    ) {
      this.promotionSearchOption.bsnCodeOptions.unshift({
        comCode: "%",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.promotionSearchOption.bsnCode = "%";

    if (
      this.promotionViewOptions.bsnCodeOptions.findIndex(
        (obj) => obj.comCode === "%"
      ) === -1
    ) {
      this.promotionViewOptions.bsnCodeOptions.unshift({
        comCode: "%",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
  },
  methods: {
    valueAccessor(field, data, column) {
      let code = data[field];
      let returnCode = null;
      if (code != null) {
        returnCode = commonCodesGetComName(column.comCode, code);
      }
      return returnCode;
    },
    promotionGridHeaderCellInfo(args) {
      if (args.cell.column.headerText === "NO") {
        args.node.classList.add(this.$t("className.grid.noSortFilter"));
      }
    },
    onPromotionSearchPopupClosed() {
      this.$emit("popupClosed", { popupData: this.popupData });
    },
    showPromotionSearchPopup: function (popupData) {
      this.popupData = popupData;
      this.promotionSearchOption.promtnName = popupData.promtnName;

      if (popupData.promtnName) {
        this.onSearchPromotion();
      }
      this.$refs.promotionSearchPopup.show();

      this.$refs.promtnNameTextBox.focus();
    },
    promotionConfirm: function () {
      if (this.selectedPromotion.promtnId) {
        this.$emit("popupClosed", {
          popupData: this.popupData,
          selectedPromotion: this.selectedPromotion,
        });
      } else {
        this.errorToast("프로모션을 선택해 주십시오");
      }
    },
    closePromotionSearchPopup() {
      this.$refs.promotionSearchPopup.hide();
    },
    onApplyDateFromChange(args) {
      if (!args.data) {
        return;
      }

      if (
        moment(this.promotionSearchOption.applyDateFrom).isAfter(
          this.promotionSearchOption.applyDateTo
        )
      ) {
        this.promotionSearchOption.applyDateTo = this.promotionSearchOption.applyDateFrom;
      }
    },
    onApplyDateToChange(args) {
      if (!args.data) {
        return;
      }

      if (
        moment(this.promotionSearchOption.applyDateFrom).isAfter(
          this.promotionSearchOption.applyDateTo
        )
      ) {
        this.promotionSearchOption.applyDateFrom = this.promotionSearchOption.applyDateTo;
      }
    },
    onSearchPromotion: function () {
      const promtnName = this.promotionSearchOption.promtnName
        ? this.promotionSearchOption.promtnName.trim() === ""
          ? null
          : this.promotionSearchOption.promtnName
        : null;
      const startDate =
        this.promotionSearchOption.applyDate === true
          ? this.promotionSearchOption.applyDateFrom
          : null;
      const endDate =
        this.promotionSearchOption.applyDate === true
          ? this.promotionSearchOption.applyDateTo
          : null;
      const bsnCode =
        this.promotionSearchOption.bsnCode === "%"
          ? null
          : this.promotionSearchOption.bsnCode;
      const promtnDiv =
        this.promotionSearchOption.promtnDiv === "ALL"
          ? null
          : this.promotionSearchOption.promtnDiv;
      const useFlag = this.promotionSearchOption.useFlag;
      getPromotionList(
        promtnName,
        this.promotionSearchOption.applyDate,
        startDate,
        endDate,
        bsnCode,
        promtnDiv,
        useFlag
      )
        .then((response) => {
          console.log("getPromotionList.===>", response);
          this.promotionList = response.value.promotionList
            ?.map(item => {
              if (item.memberGrade) {
                const memberGrades = item.memberGrade?.split(',');
                if (memberGrades) {
                  item.memberGrade = memberGrades
                    ?.map(i => commonCodesGetComName("MEMBER_GRADE", i))
                    ?.join(', ');
                }
              }
              return item;
            });
        })
        .catch((error) => {
          console.log("getPromotionList.err.===>", error);
        });
    },
    onSearchPromotionClicked: function () {
      this.selectedPromotion = {};
      this.onSearchPromotion();
    },
    promotionGridRowSelected: function (args) {
      this.selectedPromotion = args.data;
    },
    onPromotionGridDialogDoubleClickedOrEnterKeyed() {
      this.promotionConfirm();
    },
    onPromtnNameKeyDownEnter() {
      this.onSearchPromotion();
    },
    onPromotionGridDataBound() {
      if (this.promotionList.length > 0) {
        this.$refs.promotionGrid.selectRow(0);
      } else {
        document.getElementById("promtnNameTextBox").select();
      }
    },
  },
};
</script>

<template>
  <div>
    <ejs-dialog
      ref="timeAdditionalInformationPopup"
      :header="`타임 부가정보 [${resveDateTitle}]`"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      width="563"
      :close="onTimeAdditionalInformationPopupClosed"
    >
      <div class="window openReceipt">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">일자별 타임현황</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <ejs-grid-wrapper
                      ref="dailyTimeStatusGrid"
                      :provides="gridProvides"
                      :columns="dailyTimeStatusGridColumns"
                      :dataSource="dailyTimeStatusList"
                      :allowResizing="true"
                      :allowSelection="false"
                      :allowSorting="false"
                      :allowFiltering="false"
                      :enableHover="false"
                      :isNOColumnDisplay="false"
                      :isSelectedRowRetain="false"
                      @queryCellInfo="
                        timeAdditionalInformationPopupGridCustomiseCell
                      "
                    />
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-02">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">일자별 대행사 오픈현황</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <ejs-grid-wrapper
                      ref="dailyAgncyOpenStatusGrid"
                      :provides="gridProvides"
                      :columns="dailyAgncyOpenStatusGridColumns"
                      :dataSource="agncyOpenStatusList"
                      :allowResizing="true"
                      :allowSelection="false"
                      :allowSorting="false"
                      :allowFiltering="false"
                      :enableHover="false"
                      :isSelectedRowRetain="false"
                    />
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="closeTimeAdditionalInformationPopup"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>
<script>
import {
  Edit,
  VirtualScroll,
  Resize,
  ForeignKey,
  Freeze,
} from "@syncfusion/ej2-vue-grids";

import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { getDailyTimeStatus } from "@/api/timeTable";

export default {
  name: "TimeAdditionalInformationPopup",
  mixins: [commonMixin],
  components: { EjsGridWrapper,ErpButton },
  data: function () {
    return {
      resveDateTitle: "",
      dailyTimeStatusList: [],
      agncyOpenStatusList: [],
      resveStatusColumns: [
        {
          field: "totalTimeCnt",
          allowEditing: false,
          type: "number",
          headerText: "전체",
          minWidth: "16",
          width: "41",
          isNumericType: true,
          textAlign: "Center",
          valueAccessor: this.isZeroValueAccess,
        },
        {
          field: "resveTimeCnt",
          allowEditing: false,
          type: "number",
          headerText: "예약",
          minWidth: "16",
          width: "41",
          isNumericType: true,
          textAlign: "Center",
          valueAccessor: this.isZeroValueAccess,
        },
        {
          field: "remainTimeCnt",
          allowEditing: false,
          type: "number",
          headerText: "잔여",
          minWidth: "16",
          width: "41",
          isNumericType: true,
          textAlign: "Center",
          valueAccessor: this.isZeroValueAccess,
        },
      ],
      timeStatusColumns: [
        {
          field: "blockTimeCnt",
          allowEditing: false,
          type: "number",
          headerText: "블럭",
          minWidth: "16",
          width: "41",
          isNumericType: true,
          textAlign: "Center",
          valueAccessor: this.isZeroValueAccess,
        },
        {
          field: "webTimeCnt",
          allowEditing: false,
          type: "number",
          headerText: "웹",
          minWidth: "16",
          width: "41",
          isNumericType: true,
          textAlign: "Center",
          valueAccessor: this.isZeroValueAccess,
        },
        {
          field: "agncyTimeCnt",
          allowEditing: false,
          type: "number",
          headerText: "대행",
          minWidth: "16",
          width: "41",
          isNumericType: true,
          textAlign: "Center",
          valueAccessor: this.isZeroValueAccess,
        },
        {
          field: "selfTimeCnt",
          allowEditing: false,
          type: "number",
          headerText: "셀프",
          minWidth: "16",
          width: "41",
          isNumericType: true,
          textAlign: "Center",
          valueAccessor: this.isZeroValueAccess,
        },
        {
          field: "joinTimeCnt",
          allowEditing: false,
          type: "number",
          headerText: "조인",
          minWidth: "16",
          width: "41",
          isNumericType: true,
          textAlign: "Center",
          valueAccessor: this.isZeroValueAccess,
        },
      ],
      gridProvides: [Edit, VirtualScroll, Resize, ForeignKey, Freeze],
    };
  },
  computed: {
    dailyTimeStatusGridColumns() {
      return [
        {
          field: "partDiv",
          allowEditing: false,
          type: "string",
          headerText: "구분",
          minWidth: 16,
          width: 40,
          textAlign: "Center",
        },
        {
          headerText: "예약현황",
          allowEditing: false,
          textAlign: "Center",
          columns: this.resveStatusColumns,
        },
        {
          headerText: "타임상태",
          allowEditing: false,
          textAlign: "Center",
          columns: this.timeStatusColumns,
        },
      ];
    },
    dailyAgncyOpenStatusGridColumns() {
      return [
        {
          field: "agncyName",
          allowEditing: false,
          headerText: "대행사",
          minWidth: 16,
          textAlign: "Center",
        },
        {
          field: "timeCnt",
          allowEditing: false,
          headerText: "건수",
          minWidth: 16,
          width: 60,
          textAlign: "Center",
          valueAccessor: this.isZeroValueAccess,
        },
      ];
    },
  },
  methods: {
    getDailyTimeStatus: function (resveDate) {
      getDailyTimeStatus(resveDate)
        .then((response) => {
          this.resveDateTitle = resveDate;
          this.dailyTimeStatusList = response.value.dailyTimeStatusList;
          this.agncyOpenStatusList = response.value.agncyOpenStatusList;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    isZeroValueAccess(field, data) {
      const value = data[field];
      if (value === undefined) {
        return "-";
      } else {
        if (value === 0) {
          return "-";
        }
      }
      return value;
    },
    timeAdditionalInformationPopupGridCustomiseCell(args) {
      if (args.data.partDiv === "전체") {
        args.cell.classList.add(
          this.$t("className.grid.devReservationPartDivLine")
        );
      }
    },
    onTimeAdditionalInformationPopupClosed: function () {
      this.$emit("popupClosed");
    },
    closeTimeAdditionalInformationPopup: function () {
      this.$refs.timeAdditionalInformationPopup.hide();
    },
  },
};
</script>

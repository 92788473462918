<template>
  <div>
    <ejs-dialog
      ref="reservationTimeCopyPopup"
      :header="'예약타임 복사'"
      :allowDragging="true"
      :showCloseIcon="true"
      width="400"
      :animationSettings="{ effect: 'None' }"
      :isModal="true"
      :close="onReservationTimeCopyPopupClosed"
    >
      <div class="window scheduledTimeCopy">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field sheetDate">
                              <!-- 필수 : required -->
                              <div class="title">시트일자(From)</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group date">
                                    <div class="form">
                                      <input-date
                                        :format="'YYYY-MM-DD'"
                                        v-model="timeTableOption.sheetDate"
                                        type="body-data"
                                        @change="onSheetDateChange"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li class="item form-group text">
                                    ({{ timeTableOption.sheetDateDay }})
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">복사일자(To)</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-date-range
                                        v-model="copyDateRange"
                                        type="body-data"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">주중/주말</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="bsnCodeDropdown"
                                        v-model="timeTableOption.bsnCode"
                                        :dataSource="
                                          timeTableOptions.bsnCodeOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="{
                                          text: 'comName',
                                          value: 'comCode',
                                        }"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="copy keyColor">
              <erp-button
                  buttonDiv="SAVE"
                  :is-shortcut-button="true"
                  :isIconCustom="true"
                  @click.native="saveReservationTimeCopyPopup"
              >
                타임복사
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="closeReservationTimeCopyPopup"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { getFormattedDate } from "@/utils/date";
import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
} from "@/utils/commonCodes";

import InputDate from "@/components/common/datetime/InputDate";
import ErpButton from "@/components/button/ErpButton.vue";
import { copyTimeTable } from "@/api/timeTable";
import moment from "moment";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import _ from "lodash";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

export default {
  name: "ResevationTimeCopyPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: { InputDateRange, InputDate, ErpButton },
  data: function () {
    return {
      timeTableOption: {
        sheetDate: null,
        copyDateFrom: null,
        copyDateTo: null,
        sheetDateDay: null,
        bsnCode: null,
      },
      timeTableOptions: {
        bsnCodeOptions: null,
      },
    };
  },
  computed: {
    copyDateRange: {
      get: function () {
        return {
          from: this.timeTableOption.copyDateFrom,
          to: this.timeTableOption.copyDateTo,
        };
      },
      set: function (copyDateRange) {
        this.timeTableOption.copyDateFrom = copyDateRange.from;
        this.timeTableOption.copyDateTo = copyDateRange.to;
      },
    },
  },
  created: function () {
    this.timeTableOption.sheetDate = getFormattedDate(new Date());
    this.timeTableOption.copyDateFrom = getFormattedDate(new Date());
    this.timeTableOption.copyDateTo = getFormattedDate(new Date());

    this.timeTableOption.sheetDateDay = commonCodesGetComName(
      "DW_CODE",
      String(new Date().getDay() + 1)
    );

    this.timeTableOptions.bsnCodeOptions = _.orderBy(
      commonCodesGetCommonCode("BSN_CODE")?.filter(
        ({ comCode }) => comCode !== "CLOSE"
      ) || [],
      "sortNo"
    );
    if (
      this.timeTableOptions.bsnCodeOptions.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.timeTableOptions.bsnCodeOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.timeTableOption.bsnCode = "ALL";
  },
  methods: {
    onSheetDateChange: function (args) {
      if (args.data !== null) {
        this.timeTableOption.sheetDateDay = commonCodesGetComName(
          "DW_CODE",
          String(moment(args.data, "YYYY-MM-DD").day() + 1)
        );
      } else {
        this.timeTableOption.sheetDateDay = null;
      }
    },
    saveReservationTimeCopyPopup: function () {
      // TODO : 필수 입력 항목은 모르기 때문에, 입력 validation은 나중에 추가

      // TODO : API 호출
      console.log(JSON.stringify(this.timeTableOption, null, 2));
      this.copyTimeTable();
    },
    showReservationTimeCopyPopup: function (
      resveDateFrom,
      resveDateTo,
      bsnCode
    ) {
      this.timeTableOption.sheetDate = resveDateFrom;
      this.timeTableOption.copyDateFrom = resveDateFrom;
      this.timeTableOption.copyDateTo = resveDateTo;
      this.timeTableOption.bsnCode = bsnCode;
      this.$refs.reservationTimeCopyPopup.show();
    },
    closeReservationTimeCopyPopup: function () {
      this.$refs.reservationTimeCopyPopup.hide();
    },
    onReservationTimeCopyPopupClosed: function () {
      this.$emit("popupClosed");
    },
    copyTimeTable() {
      const data = {
        ...this.timeTableOption,
        bsnCode:
          this.timeTableOption.bsnCode === "ALL"
            ? null
            : this.timeTableOption.bsnCode,
      };

      copyTimeTable(data)
        .then((response) => {
          console.log("copyTimeTable.===>", response);
          if (response.status === "OK") {
            this.infoToast("타임이 복사되었습니다");
            this.$emit("popupClosed", {
              isReload: true,
              timeTableOption: this.timeTableOption,
            });
            this.$refs.reservationTimeCopyPopup.hide();
          }
        })
        .catch((error) => {
          console.log("copyTimeTable.err.===>", error);
        });
    },
  },
};
</script>

<template>
  <div>
    <ejs-dialog
      header="프로모션 삭제"
      height="800"
      width="1180"
      :animationSettings="{ effect: 'None' }"
      ref="dialog"
      v-bind="dialogProps"
      @close="onDialogClose"
      @overlayClick="onDialogOverlayClick"
    >
      <div class="window promotionDelete">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">예약일자</div>
                    <ul class="content">
                      <li class="item dateRange">
                        <input-date-range
                          type="lookup-condition"
                          v-model="reservationDateRange"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      buttonDiv="GET"
                      :isShortcutButton="true"
                      @click.native="onSearchButtonClick"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
              <div class="lookup-right">
                <ul class="lookup-button">
                  <li>
                    <erp-button
                        buttonDiv="SAVE"
                        @click.native="onSelectAllButtonClick"
                    >
                      전체선택
                    </erp-button>
                  </li>
                  <li class="cancel">
                    <erp-button
                        buttonDiv="SAVE"

                        @click.native="onUnselectAllButtonClick"
                    >
                      선택취소
                    </erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper ref="grid" v-bind="gridProps" />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="delete keyColor">
              <erp-button
                  buttonDiv="DELETE"
                  :is-shortcut-button="true"

                  @click.native="onSubmitClick"
              >
                삭제
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="onCloseClick"

              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import { getFormattedDate } from "@/utils/date";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { v4 as createUUID } from "uuid";
import { Edit, ForeignKey, Resize } from "@syncfusion/ej2-vue-grids";
import { getTimeTable, putTimeTable } from "@/api/timeTable";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

export default {
  name: "PromotionDeletionPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    InputDateRange,
    ErpButton
  },
  data() {
    return {
      isPriceView: null,
      reservationDateRange: {
        from: getFormattedDate(new Date()),
        to: getFormattedDate(new Date()),
      },
      timeTableList: [],
    };
  },
  computed: {
    dialogProps() {
      return {
        allowDragging: true,
        isModal: true,
        animationSettings: { effect: "None" },
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };
    },
    gridProps() {
      return {
        allowFiltering: false,
        allowSorting: false,
        columns: [
          {
            allowEditing: false,
            field: "resveDate",
            headerText: "일자",
            minWidth: 16,
            textAlign: "Left",
            width: 78,
          },
          {
            allowEditing: false,
            groupCode: "DW_CODE",
            field: "dwCode",
            isCommonCodeField: true,
            headerText: "요일",
            minWidth: 16,
            textAlign: "Center",
            width: 60,
          },
          {
            allowEditing: false,
            groupCode: "PART_DIV",
            field: "partDiv",
            isCommonCodeField: true,
            headerText: "부",
            minWidth: 16,
            textAlign: "Center",
            width: 60,
          },
          {
            allowEditing: false,
            field: "resveTime",
            headerText: "시간",
            minWidth: 16,
            textAlign: "Left",
            width: 48,
          },
          {
            allowEditing: false,
            groupCode: "COURSE_CODE",
            field: "resveCourse",
            isCommonCodeField: true,
            headerText: "코스",
            minWidth: 16,
            textAlign: "Left",
            width: 70,
          },
          {
            allowEditing: false,
            groupCode: "HOLE_DIV",
            field: "holeDiv",
            isCommonCodeField: true,
            headerText: "홀",
            minWidth: 16,
            textAlign: "Left",
            width: 60,
          },
          {
            allowEditing: false,
            groupCode: "TIME_DIV",
            field: "timeDiv",
            isCommonCodeField: true,
            headerText: "타임구분",
            minWidth: 16,
            textAlign: "Left",
            width: 71,
          },
          {
            allowEditing: false,
            groupCode: "TIME_STATUS",
            field: "timeStatus",
            isCommonCodeField: true,
            headerText: "타임상태",
            minWidth: 16,
            textAlign: "Left",
            width: 90,
          },
          {
            allowEditing: false,
            displayAsCheckBox: true,
            editType: "booleanedit",
            field: "webOpenFlag",
            headerText: "W",
            minWidth: 16,
            textAlign: "Center",
            type: "boolean",
            width: 32,
          },
          {
            allowEditing: false,
            displayAsCheckBox: true,
            editType: "booleanedit",
            field: "agncyOpenFlag",
            headerText: "A",
            minWidth: 16,
            textAlign: "Center",
            type: "boolean",
            width: 32,
          },
          {
            allowEditing: false,
            displayAsCheckBox: true,
            editType: "booleanedit",
            field: "selfOpenFlag",
            headerText: "S",
            minWidth: 16,
            textAlign: "Center",
            type: "boolean",
            width: 32,
          },
          {
            allowEditing: false,
            displayAsCheckBox: true,
            editType: "booleanedit",
            field: "joinOpenFlag",
            headerText: "J",
            minWidth: 16,
            textAlign: "Center",
            type: "boolean",
            width: 32,
          },
          {
            allowEditing: false,
            field: "webEndDt",
            headerText: "웹 마감일시",
            minWidth: 16,
            textAlign: "Left",
            width: 128,
          },
          {
            allowEditing: false,
            field: "promtnName",
            headerText: "프로모션",
            minWidth: 16,
            textAlign: "Left",
            valueAccessor: (field, data) => {
              if (data["promtnId"]) {
                return data[field];
              } else {
                return "";
              }
            },
            width: 180,
          },
          {
            allowEditing: false,
            field: "promtnPrice",
            headerText: "프로모션요금",
            isNumericType: true,
            inputNumberProperty: {
              allowDot: true,
              allowMinus: true,
            },
            minWidth: 16,
            textAlign: "Right",
            width: 95,
          },
          {
            allowEditing: false,
            field: "eventPrice",
            headerText: "이벤트요금",
            isNumericType: true,
            inputNumberProperty: {
              allowDot: true,
              allowMinus: true,
            },
            minWidth: 16,
            textAlign: "Right",
            width: 95,
          },
          {
            allowEditing: false,
            field: "noPrice",
            headerText: "정상가",
            isNumericType: true,
            inputNumberProperty: {
              allowDot: true,
              allowMinus: true,
            },
            minWidth: 16,
            textAlign: "Right",
            width: 90,
          },
          {
            allowEditing: false,
            field: "dcPrice",
            headerText: "할인가",
            isNumericType: true,
            inputNumberProperty: {
              allowDot: true,
              allowMinus: true,
            },
            minWidth: 16,
            textAlign: "Right",
            width: 90,
          },
          {
            allowEditing: false,
            groupCode: "RESVE_RTRVL_METHOD",
            field: "resveRtrvlMethod",
            isCommonCodeField: true,
            headerText: "타임회수",
            minWidth: 16,
            textAlign: "Left",
            width: 90,
          },
          {
            field: "timeId",
            visible: false,
          },
          {
            field: "dwName",
            visible: false,
          },
          {
            field: "hldyFlag",
            visible: false,
          },
          {
            field: "hldyName",
            visible: false,
          },
          {
            field: "promtnId",
            visible: false,
          },
          {
            field: "orgTimeStatus",
            visible: false,
          },
          {
            field: "timeStatusFlag",
            visible: false,
          },
          {
            field: "partDivName",
            visible: false,
          },
          {
            field: "dcPaymtCode",
            visible: false,
          },
          {
            field: "linkTimeId",
            visible: false,
          },
          {
            field: "shotgunFlag",
            visible: false,
          },
          {
            field: "startHoleDiv",
            visible: false,
          },
          {
            field: "roundGroupNo",
            visible: false,
          },
          {
            field: "resveChannel",
            visible: false,
          },
          {
            field: "resveChannelName",
            visible: false,
          },
          {
            field: "resveChannelList",
            visible: false,
          },
          {
            field: "aditTeamFlag",
            visible: false,
          },
          {
            field: "galleyTeamFlag",
            visible: false,
          },
          {
            field: "webOpenDt",
            visible: false,
          },
          {
            field: "remarks",
            visible: false,
          },
          {
            field: "optFlag",
            visible: false,
          },
          {
            field: "holdResnRemarks",
            visible: false,
          },
          {
            field: "bsnCode",
            visible: false,
          },
          {
            field: "paymtPriceJson",
            visible: false,
          },
          {
            field: "_rid",
            isPrimaryKey: true,
            visible: false,
          },
        ],
        dataSource: !this.timeTableList
          ? []
          : this.timeTableList.map((record) => ({
              ...record,
              _rid: createUUID(),
            })),
        provides: [Edit, ForeignKey, Resize],
        selectionSettings: {
          checkboxOnly: false,
          mode: "Row",
          type: "Multiple",
        },
      };
    },
  },
  methods: {
    async showPopup({ resveDateFrom, resveDateTo, isPriceView } = {}) {
      this.isPriceView = isPriceView;
      this.reservationDateRange = {
        from: resveDateFrom,
        to: resveDateTo,
      };

      await this.fetchTimeTablePromotedOnly();
      this.$refs["dialog"].show();
    },
    hidePopup() {
      this.$refs["dialog"].hide();
    },

    // event handlers
    onDialogClose() {
      this.$emit("close");
    },
    onDialogOverlayClick() {
      this.hidePopup();
    },
    async onSearchButtonClick() {
      await this.fetchTimeTablePromotedOnly();
    },
    onSelectAllButtonClick() {
      const gridRef = this.$refs["grid"];
      gridRef.selectRowsByRange(0, gridRef.getBatchCurrentViewRecords().length);
    },
    onUnselectAllButtonClick() {
      this.$refs["grid"].clearSelection();
    },
    async onSubmitClick() {
      if (!(await this.confirm("프로모션 설정을 삭제하시겠습니까?"))) {
        return;
      }

      await this.deletePromotionFromSelectionTimeTable();
    },
    onCloseClick() {
      this.hidePopup();
    },

    async fetchTimeTablePromotedOnly() {
      const { timeTableList } = await this.getTimeTable(
        this.reservationDateRange.from,
        this.reservationDateRange.to,
        true
      );

      this.timeTableList = timeTableList;
    },
    async deletePromotionFromSelectionTimeTable() {
      const changedRecords = this.$refs["grid"]
        .getSelectedRecords()
        .map((record) => ({
          timeId: record.timeId,
          resveDate: record.resveDate,
          optFlag: record.optFlag,
          promtnId: null,
        }));

      const data = {
        addedRecords: [],
        changedRecords,
        deletedRecords: [],
      };

      await this.putTimeTable(data);
      this.infoToast(this.$t("main.popupMessage.deleted"));
      this.hidePopup();
    },

    // API
    async getTimeTable(resveDateFrom, resveDateTo, promotedOnly) {
      const {
        value: { timeTableList },
      } = await getTimeTable(
          this.isPriceView,
        undefined,
        undefined,
        undefined,
        resveDateFrom,
        resveDateTo,
        undefined,
        undefined,
        undefined,
        undefined,
        promotedOnly
      );

      return { timeTableList };
    },
    async putTimeTable(data) {
      await putTimeTable(data);
    },
  },
};
</script>

<style scoped></style>

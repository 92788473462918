import request from "@/utils/request";

/**
 * @return Promise
 */
export function getPromotionList(
  promtnName,
  dateUseFlag,
  startDate,
  endDate,
  bsnCode,
  promtnDiv,
  useFlag,
  promtnId = null,
  memberGrade = null,
  isBackground = false
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/standard_info/promotion/",
    method: "get",
    isBackground: isBackground,
    params: {
      promtnName: promtnName === "" ? null : promtnName,
      dateUseFlag: dateUseFlag === "" ? null : dateUseFlag,
      applyDeFrom: startDate === "" ? null : startDate,
      applyDeTo: endDate === "" ? null : endDate,
      bsnCode: bsnCode === "" ? null : bsnCode,
      promtnDiv: promtnDiv === "" ? null : promtnDiv,
      useFlag: useFlag === "" ? null : useFlag,
      promotionId: promtnId === "" ? null : promtnId,
      memberGrade: memberGrade === "" ? null : memberGrade,
    },
  });
}

/**
 * @return Promise
 */
export function getPromotionInfo(promtnId) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/standard_info/promotion/detail",
    method: "get",
    params: {
      promtnId: promtnId,
    },
  });
}

/**
 * @return Promise
 */
export function addPromotion(promotionInfo) {
  return request({
    url: "/reservation_management/standard_info/promotion/",
    method: "post",
    data: promotionInfo,
  });
}

/**
 * @return Promise
 */
export function putPromotion(promotionInfo) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/standard_info/promotion/",
    method: "put",
    data: promotionInfo,
  });
}

/**
 * @return Promise
 */
export function applyPromotion(promtnId) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/reservation_management/standard_info/promotion/apply/${promtnId}`,
    method: "put",
    data: {},
  });
}

/**
 * @return Promise
 */
export function cancelPromotion(promtnId) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/reservation_management/standard_info/promotion/cancel/${promtnId}`,
    method: "put",
    data: {},
  });
}

/**
 * @return Promise
 */
export function deletePromotion(promtnId) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/standard_info/promotion/",
    method: "delete",
    params: {
      promtnId: promtnId,
    },
  });
}

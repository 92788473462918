<template>
  <div>
    <ejs-dialog
      ref="blockTimeSettingPopup"
      :header="popupTitle"
      :allowDragging="true"
      :showCloseIcon="true"
      :width="popupDialogWidth"
      height="800"
      :isModal="true"
      :created="onBlockTimeSettingPopupCreated"
      :close="onBlockTimeSettingPopupClosed"
    >
    </ejs-dialog>
    <div
      id="blockTimeSettingPopupContentTemplate"
      class="window blockTimeSetting"
    >
      <div class="windowContent">
        <div class="content-wrapper">
          <div class="content-body">
            <article class="body-article">
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section class="article-section section-01">
                <div
                  class="section-body"
                  :class="reservationCourseSectionBodyClassName"
                >
                  <ejs-grid-wrapper
                    v-for="(
                      timeTableCourse, timeTableCourseIdx
                    ) in timeTableCourseLists"
                    :key="timeTableCourse.courseCode"
                    :style="propsData"
                    ref="timeTableGrid"
                    :provides="gridProvides"
                    :columns="courseDivColumns"
                    :dataSource="
                      timeTableCourseLists[timeTableCourseIdx].timeTables
                    "
                    :class="courseActiveIndex===timeTableCourseIdx ? 'border-box' : ''"
                    :editSettings="timeTableGridEditSettings"
                    :allowResizing="true"
                    :allowSorting="true"
                    :allowFiltering="true"
                    @click.native="courseDivClicked(timeTableCourseIdx)"
                    @queryCellInfo="timeTableQueryCellInfo"
                    @headerCellInfo="
                      timeTableHeaderCellInfo($event, timeTableCourseIdx)
                    "
                    @dataBound="dataBoundEvent"
                  />
                </div>
              </section>
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section class="article-section section-02">
                <div class="section-body">
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">비고</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-textarea
                                        ref="remarks"
                                        v-model="remarks"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </div>
              </section>
            </article>
          </div>
        </div>
      </div>
      <div class="windowFooter">
        <ul class="button">
          <li class="apply keyColor">
            <erp-button
                buttonDiv="GET"
                @click.native="getTimeTableByTimeStatus"
                :isShortcutButton="true"
                :isIconCustom="true"
            >
              새로고침
            </erp-button>
          </li>
          <li class="apply keyColor">
            <erp-button
                buttonDiv="SAVE"
                :isShortcutButton="true"
                :isIconCustom="true"
                @click.native="applyButtonClicked"
            >
              적용
            </erp-button>
          </li>
          <li class="close">
            <erp-button
                buttonDiv="CLOSE"
                @click.native="closeBlockTimeSettingPopup"
                :isIconCustom="true"
            >
              닫기
            </erp-button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {
  Edit,
  Filter,
  Sort,
  VirtualScroll,
  Resize,
  ForeignKey,
} from "@syncfusion/ej2-vue-grids";

import ErpButton from "@/components/button/ErpButton.vue";
import { validateFormRefs } from "@/utils/formUtil";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import commonMixin from "@/views/layout/mixin/commonMixin";

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { reservationCourseGridSectionBodyClassName } from "@/utils/gridUtil";

import { getTimeTableByTimeStatus } from "@/api/timeTable";
import { Tooltip } from "@syncfusion/ej2-popups";
import InputTextarea from "@/components/common/text/InputTextarea";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {sortBy as _sortBy} from 'lodash';
import {getFormattedDate} from "@/utils/date";

export default {
  name: "BlockTimeSettingPopup",
  mixins: [commonMixin, confirmDialogMixin],
  props:['propsData','filterCourse'],
  components: {
    InputTextarea,
    EjsGridWrapper,
    ErpButton
  },
  data: function () {
    return {
      popupDialogWidth: null,
      remarks: "",
      popupTitle: null,
      timeStatus: null, // 'BLOCK' or 'HOLD'
      timeOption: {},
      timeTableCourseLists: [],
      courseActiveIndex: -1,
      blockTimeSettingOption: {
        remarks: null,
      },
      timeTableViewOption: {
        partDivOption: commonCodesGetCommonCode("PART_DIV"),
      },
      numberTemplate: function () {
        return {
          template: Vue.component("editOption", {
            template: "<span>{{parseInt(data.index) + 1}}</span>",
            data() {
              return {
                data: {
                  data: {},
                },
              };
            },
          }),
        };
      },
      timeTableGridEditSettings: {
        allowEditing: true,
        allowAdding: false,
        allowDeleting: false,
        mode: "Batch",
        showConfirmDialog: false,
      },
      timeTableGridFilterSettings: { type: "Menu" },
      courseDivColumns: [
        {
          field: "timeId",
          isPrimaryKey: true,
          allowEditing: false,
          type: "integer",
          visible: false,
        },
        {
          allowEditing: true,
          field: "resveCourse",
          headerText: "코스",
          textAlign: "center",
          columns: [
            {
              field: "resveDateMMDD",
              allowEditing: false,
              type: "string",
              headerText: "일자",
              minWidth: "16",
              width: "70",
              textAlign: "center",
              isDateType: true,
            },
            {
              field: "dwName",
              allowEditing: false,
              type: "string",
              headerText: "요일",
              minWidth: "16",
              width: "60",
              textAlign: "center",
            },
            {
              field: "partDivName",
              allowEditing: false,
              type: "string",
              headerText: "부",
              minWidth: "16",
              width: "60",
              textAlign: "center",
            },
            {
              field: "resveTime",
              allowEditing: false,
              type: "string",
              headerText: "시간",
              minWidth: "16",
              width: "70",
              textAlign: "center",
            },
            {
              field: "timeStatusFlag",
              headerText: "",
              editType: "booleanedit",
              type: "boolean",
              displayAsCheckBox: true,
              isSelectAllColumn: true,
              textAlign: "Center",
              minWidth: "16",
              width: "40",
            },
          ],
        },
      ],
      gridProvides: [Edit, Filter, Sort, VirtualScroll, Resize, ForeignKey],
      // remarks INPUT에 v-model 적용을 못하는 상황이라 formValidate 적용 못함.
      blockTimeSettingPopupValidateRefList: {
        remarks: { required: true },
      },
    };
  },
  computed: {
    reservationCourseSectionBodyClassName() {
      return reservationCourseGridSectionBodyClassName(
        this.timeTableCourseLists,
        this.$t("className.reservation.reservationCourseNumPrefix")
      );
    },
  },
  created: function () {
    const courseCodes = commonCodesGetCommonCode("COURSE_CODE", true);

    this.popupDialogWidth = Math.round(393.3 * courseCodes.length);
  },
  methods: {
    validateFormRefs,
    timeTableQueryCellInfo(args) {
      const field = args.column.field;
      const cellData = args.data;
      if (field === "timeStatusFlag") {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (field === "resveTime") {
        if (cellData.holdResnRemarks != null) {
          // eslint-disable-next-line no-unused-vars
          let tooltip = new Tooltip(
            {
              content: cellData.holdResnRemarks,
            },
            args.cell
          );
        }
      }
    },
    timeTableHeaderCellInfo(args, timeTableCourseIdx) {
      if (args.cell.column.field === "resveCourse") {
        args.node.innerText =
          this.timeTableCourseLists[timeTableCourseIdx].courseName;
        args.node.style.textAlign = "center";
      } else if (args.cell.column.headerText === "NO") {
        args.node.classList.add(this.$t("className.grid.noSortFilter"));
      }
    },
    onBlockTimeSettingPopupCreated() {
      this.$refs.blockTimeSettingPopup.ej2Instances.content = document.getElementById(
        "blockTimeSettingPopupContentTemplate"
      );
    },
    onBlockTimeSettingPopupClosed: function () {
      this.$emit("popupClosed");
    },
    showBlockTimeSettingPopup: function (
      timeStatus,
      timeOption
    ) {
      if (timeStatus === "BLOCK" || timeStatus === "HOLD") {
        this.timeStatus = timeStatus;
        this.setPopupTitle(timeStatus);
        this.timeOption = timeOption;
        this.$refs.blockTimeSettingPopup.show();
        this.getTimeTableByTimeStatus();
      } else {
        this.errorToast("파라미터가 잘못되었습니다");
      }
    },
    setPopupTitle: function (timeStatus) {
      if (timeStatus === "BLOCK") {
        this.popupTitle = "블럭타임 설정";
      } else if (timeStatus === "HOLD") {
        this.popupTitle = "홀딩 설정";
      }
    },
    closeBlockTimeSettingPopup: function () {
      this.$refs.blockTimeSettingPopup.hide();
    },
    // saveBlockTimeSettingPopup: function () {
    //   let data = {
    //     remarks: this.remarks,
    //     timeTableRequestList: []
    //   };
    //   let row = 0;
    //   const gridLength = this.$refs.timeTableGrid.length;
    //
    //   let setTrueCount = 0;
    //
    //   for (let i = 0; i < gridLength; i++) {
    //     this.$refs.timeTableGrid[i].saveCell();
    //
    //     const changedRecords = this.$refs.timeTableGrid[i].getBatchChanges().changedRecords;
    //
    //     changedRecords.forEach(record => {
    //       data.timeTableRequestList[row] = record;
    //       if (record.timeStatusFlag) {
    //         setTrueCount++;
    //         data.timeTableRequestList[row].timeStatusFlag = true;
    //         data.timeTableRequestList[row].timeStatus = this.timeStatus;
    //       } else {
    //         data.timeTableRequestList[row].timeStatusFlag = false;
    //         data.timeTableRequestList[row].timeStatus = 'EMPTY';
    //       }
    //
    //       row++;
    //     });
    //   }
    //
    //   if (setTrueCount > 0) {
    //     // remarks INPUT에 v-model 적용을 못하는 상황이라 formValidate 적용 못해서 대체 작업.
    //     // 체크한 경우만 비고 체크.
    //     if (data.remarks === null || data.remarks === undefined || data.remarks.trim() === "") {
    //       this.$EventBus.$emit('errToast', this.$t("main.validationMessage.requiredMessage", ["비고"]));
    //       return;
    //     }
    //   }
    //
    //   this.saveTimeStatusSetting(data);
    // },
    courseDivClicked: function (courseIdx) {
      this.courseActiveIndex = courseIdx;
    },
    getTimeTableByTimeStatus() {
      const { resveDateFrom, resveDateTo, bsnCode, partDiv, resveCourse, resveTimeFrom, resveTimeTo } = this.timeOption;
      getTimeTableByTimeStatus(
        bsnCode,
        partDiv,
        resveCourse,
        resveDateFrom,
        resveDateTo,
        resveTimeFrom,
        resveTimeTo,
        this.timeStatus
      )
        .then((response) => {
          console.log("getTimeTableByTimeStatus.===>", response);
          this.timeTableCourseLists = response.value.timeTableCourseLists?.filter((list)=>this.filterCourse.includes(list.courseCode))
            ?.map(item => {
              if (item.timeTables) {
                item.timeTables = _sortBy(item.timeTables, ["partDiv", "resveTime"]).map(item => ({
                  ...item,
                  resveDateMMDD: getFormattedDate(item.resveDate, "MM/DD")
                }));
              }
              return item;
            });
        })
        .catch((error) => {
          console.log("getTimeTableByTimeStatus.err.===>", error);
        });
    },
    // saveTimeStatusSetting (data) {
    //   console.log('data.===>', data);
    //   if (data.timeTableRequestList.length === 0) {
    //     this.errorToast(this.$t('main.popupMessage.noDataToSave'));
    //     return;
    //   }
    //   saveTimeStatusSetting(this.timeStatus, data).then(response => {
    //     console.log('saveTimeStatusSetting.===>', response);
    //     this.$emit('popupClosed', { isReload: true });
    //     this.$refs.blockTimeSettingPopup.hide();
    //   }).catch(error => {
    //     console.log('saveTimeStatusSetting.err.===>', error);
    //   });
    // },
    dataBoundEvent() {},
    async applyButtonClicked() {
      let reservationTimes = [];
      let setTrueCount = 0;

      this.$refs.timeTableGrid.forEach((grid) => {
        grid.saveCell();

        const { changedRecords: changedRecords } = grid.getBatchChanges();

        changedRecords.forEach((record) => {
          const reservationTime = {
            timeId: record.timeId,
            optFlag: record.optFlag,
            resveDate: record.resveDate,
            settingTimeStatus: this.timeStatus,
            settingTimeStatusRemarks: this.remarks,
            timeSelectCheck: record.timeStatusFlag,
          };

          reservationTimes.push(reservationTime);

          if (record.timeStatusFlag) {
            setTrueCount++;
          }
        });
      });

      if (!(reservationTimes.length > 0)) {
        this.errorToast(this.$t("main.popupMessage.noDataToSave"));
        return;
      }

      if (
        setTrueCount > 0 &&
        (this.remarks === null ||
          this.remarks === undefined ||
          this.remarks.trim() === "")
      ) {
        this.$EventBus.$emit(
          "errToast",
          this.$t("main.validationMessage.requiredMessage", ["비고"])
        );
        return;
      }

      await GolfErpAPI.reservationTimesStatusSetting(reservationTimes);

      this.$emit("popupClosed", { isReload: true });
      this.$refs.blockTimeSettingPopup.hide();
    },
  },
};
</script>
<style scoped>
.border-box {
  border: 2px solid #1f5b41 !important;
  box-sizing: border-box;
  z-index: 10;
}
</style>
